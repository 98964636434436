import { useSelector } from 'react-redux';

import { Grid } from '@interstate/components/Grid';

import { CASH } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import { TermsTab } from './common/Terms.style';
import RetailPriceField from './common/RetailPriceField.interstate';
import SellingPriceField from './common/SellingPriceField.interstate';
import PaymentGridToggle from './common/PaymentGridToggle';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { LeaseHeader } from './Lease.styles';
import { Typography } from '@interstate/components/Typography';
import { ContainerGrid } from '../common/Layout.style';
import { ContainerGridItem } from '../common/ContainerGridItem';

const Cash = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    if (currentOfferType !== CASH) return null;

    const cashFields = [
        { component: <RetailPriceField />, key: 'RetailPriceField' },
        { component: <SellingPriceField />, key: 'SellingPriceField' }
    ];

    return (
        <TermsTab data-testid="cash-terms">
            {isABPencilEnabled && (
                <LeaseHeader>
                    <Typography data-testid="cash-title" variant="h5" sx={{ marginBottom: '1rem' }}>
                        Payment Information
                    </Typography>
                </LeaseHeader>
            )}
            {usePaymentGridMV && (
                <Grid xs={12}>
                    <PaymentGridToggle currentOfferType={currentOfferType} disabled={true} />
                </Grid>
            )}
            <ContainerGrid>
                {cashFields.map(({ component, key }) => (
                    <ContainerGridItem key={key} xs={6}>
                        {component}
                    </ContainerGridItem>
                ))}
            </ContainerGrid>
        </TermsTab>
    );
};

export default Cash;
