// TODO: remove this file when centralized components between here and activities-desking are used
import { FC, ReactNode } from 'react';

// components
import EnhancedDealSummaryItem from './EnhancedDealSummaryItem';
import { TypographyVariant } from '@interstate/components/Typography';

export interface IDealSummaryItemWrapperProps {
    children?: ReactNode;
    label: string;
    useWrapper?: boolean;
    value: string;
    showLargeValue?: boolean;
    isExpanded?: boolean;
    hasDelta?: boolean;
    paddingY?: number;
    pr?: number;
    prChildren?: number;
    plChildren?: number;
    labelTypography?: TypographyVariant;
    valueTypography?: TypographyVariant;
}

export const EnhancedDealSummaryItemWrapper: FC<IDealSummaryItemWrapperProps> = ({
    children,
    label,
    useWrapper = true,
    value,
    showLargeValue,
    isExpanded,
    hasDelta = false,
    paddingY = 0,
    pr,
    prChildren = 2,
    plChildren = 36,
    labelTypography = 'body-sm',
    valueTypography = 'strong-md'
}) => {
    if (useWrapper) {
        return (
            <EnhancedDealSummaryItem
                label={label}
                value={value}
                showLargeValue={showLargeValue}
                isExpanded={isExpanded}
                hasDelta={hasDelta}
                paddingY={paddingY}
                pr={pr}
                prChildren={prChildren}
                plChildren={plChildren}
                labelTypography={labelTypography}
                valueTypography={valueTypography}
            >
                {children}
            </EnhancedDealSummaryItem>
        );
    } else {
        return <>{children}</>;
    }
};
