// externals
import React from 'react';
import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { SimpleTable, TableCell } from '@interstate/components/SimpleTable';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// components
import TableHead from './TableHead';
import TableBody from './TableBody';

// styles
import { StyledSpan, StyledTableCell } from './TaxCard.style';

// consts/enums
import { DATA_DENSITY } from '../../common/taxesAndFeesConsts';
import { COUNTY_TAX_NAME, CITY_TAX_NAME, LOCAL_TAX_NAME, STATE_TAX_NAME, TAXTABLECOLUMNS, TAX_ITEM_KEYS } from '../constants';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import TaxItemRateField from './TaxItemRateField';
import { TaxDetail } from '../types';
import { DefaultFallback } from '../../common/taxesAndFeesComponents';
import TaxItemAmountField from './TaxItemAmountField';

const TaxItemSummaryDetailed: React.FC<{
    taxItem: paymentServicesTypes.TaxItem;
    handleChange: (e: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => void;
}> = ({ taxItem, handleChange }) => {
    const { stateTaxRate, stateTax, countyTaxRate, countyTax, cityTaxRate, cityTax, localTaxRate, localTax } =
        taxItem.taxBreakdown || /* istanbul ignore next */ {};
    const {
        stateTaxRate: isEditedStateTaxRate = false,
        stateTax: isEditedStateTax = false,
        countyTaxRate: isEditedCountyTaxRate = false,
        countyTax: isEditedCountyTax = false,
        cityTaxRate: isEditedCityTaxRate = false,
        cityTax: isEditedCityTax = false,
        localTaxRate: isEditedLocalTaxRate = false,
        localTax: isEditedLocalTax = false
    } = taxItem.manualChanges || {};

    const taxDetails = [
        {
            name: STATE_TAX_NAME,
            rate: stateTaxRate,
            amount: stateTax,
            key: TAX_ITEM_KEYS.STATE_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.STATE_TAX,
            isEditedRate: isEditedStateTaxRate,
            isEditedAmount: isEditedStateTax
        },
        {
            name: COUNTY_TAX_NAME,
            rate: countyTaxRate,
            amount: countyTax,
            key: TAX_ITEM_KEYS.COUNTY_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.COUNTY_TAX,
            isEditedRate: isEditedCountyTaxRate,
            isEditedAmount: isEditedCountyTax
        },
        {
            name: CITY_TAX_NAME,
            rate: cityTaxRate,
            amount: cityTax,
            key: TAX_ITEM_KEYS.CITY_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.CITY_TAX,
            isEditedRate: isEditedCityTaxRate,
            isEditedAmount: isEditedCityTax
        },
        {
            name: LOCAL_TAX_NAME,
            rate: localTaxRate,
            amount: localTax,
            key: TAX_ITEM_KEYS.LOCAL_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.LOCAL_TAX,
            isEditedRate: isEditedLocalTaxRate,
            isEditedAmount: isEditedLocalTax
        }
    ];

    const isOverrideTaxRateForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxRateForPaymentEnabled);
    const isOverrideTaxAmountForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxAmountForPaymentEnabled);
    const hasManualTotalTax = useSelector(offerReduxSelectors.hasManualTotalTax);

    const isRateEditable = isOverrideTaxRateForPaymentEnabled && taxItem.class === 'primary' && taxItem.taxBasisAmount !== 0;
    const isAmountEditable = isOverrideTaxAmountForPaymentEnabled && taxItem.class === 'primary' && taxItem.taxBasisAmount !== 0;

    const renderRow = (detail: TaxDetail, index: number) => (
        <React.Fragment key={`detailed-cell-${index}-${detail.name}-${detail.amount}`}>
            <TableCell>
                <StyledTableCell textAlign="left" data-testid={`detailed-table-cell-${detail.name}`}>
                    {hasManualTotalTax ? (
                        <StyledSpan paddingLeft={1}>
                            <DefaultFallback />
                        </StyledSpan>
                    ) : (
                        `${detail.name}`
                    )}
                </StyledTableCell>
            </TableCell>
            <TableCell key={`detail-cell-${index}-${detail.rate}-${detail.key}-${taxItem.taxName}`}>
                {isRateEditable ? (
                    <StyledTableCell data-testid={`detailed-table-cell-${detail.rate}-${detail.key}`} textAlign="center">
                        {hasManualTotalTax ? <DefaultFallback /> : <TaxItemRateField data={detail} handleBlur={handleChange} />}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell textAlign="center" data-testid={`detailed-table-cell-${detail.rate}`}>
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax && (detail.rate == undefined ? <DefaultFallback /> : `${detail.rate}%`)}
                    </StyledTableCell>
                )}
            </TableCell>
            <TableCell key={`detail-cell-amount-${index}-${detail.key}-${taxItem.taxName}`}>
                {isAmountEditable ? (
                    <StyledTableCell data-testid={`detailed-table-cell-${detail.amount}-${detail.key}`} textAlign="right">
                        {hasManualTotalTax ? <DefaultFallback /> : <TaxItemAmountField data={detail} handleBlur={handleChange} />}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell textAlign="right" data-testid={`detailed-table-cell-${detail.amount}`}>
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax &&
                            (detail.rate == undefined ? <DefaultFallback /> : formatDollarsAndCents(detail.amount))}
                    </StyledTableCell>
                )}
            </TableCell>
        </React.Fragment>
    );

    return (
        <SimpleTable data-testid="expanded-table-data-testid" dataDensity={DATA_DENSITY} background="white">
            <TableHead columns={TAXTABLECOLUMNS} />
            <TableBody data={taxDetails} renderRow={renderRow} />
        </SimpleTable>
    );
};

export default TaxItemSummaryDetailed;
