// libraries
import { offerReduxSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import type { DealHistory } from '@makemydeal/dr-dash-types';

// components
import { Grid } from '@interstate/components/Grid';
import DealSummaryItem from '../common/DealSummaryItem';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// hooks
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// utils
import { formatDollarsAndCents, formatNumber } from '@makemydeal/dr-common-utils';

export type TotalResidualProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const TotalResidual = ({ deal }: TotalResidualProps) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const adjustedResidualAmount = useDealRootSelector(offerReduxSelectors.getAdjustedResidualValue);
    const totalResidual = formatDollarsAndCents(adjustedResidualAmount); // showing dollar regardless of selected override type
    const baseResidualAmount = useDealRootSelector(offerReduxSelectors.getBaseResidualDollar);
    const baseResidualPercent = useDealRootSelector(offerReduxSelectors.getBaseResidualPercent);
    const milesPerYear: number = useDealRootSelector(offerReduxSelectors.getAnnualMilesByTerm);
    const existingMileage = useDealRootSelector(vehicleSelectors.getMileage);

    return (
        <DealSummaryItem label={constants.TOTAL_RESIDUAL} value={totalResidual}>
            <Grid>
                <LineItemWithChildren label={constants.BASE_RESIDUAL} value={formatDollarsAndCents(baseResidualAmount)} />
                <LineItemWithChildren label={constants.MILES_PER_YEAR} value={formatNumber(milesPerYear)} />
                <LineItemWithChildren label={constants.EXISTING_MILEAGE} value={formatNumber(existingMileage)} />
                <br />
                <LineItemWithChildren label={constants.BASE_RESIDUAL_PERCENT} value={`${baseResidualPercent}%`} />
            </Grid>
        </DealSummaryItem>
    );
};

export default TotalResidual;
