export const adjustOfferCardHeights = () => {
    const cardSlider = document.querySelector('#deal-comparison-offer-cards-carousel-scroller');
    const offerCards = cardSlider?.querySelectorAll('.offer-card');
    const dealPulseContainer = cardSlider?.querySelector('#deal-pulse-container');
    const dealPulseSeparator = cardSlider?.querySelector('#deal-pulse-separator');
    const dealPulseHeader = cardSlider?.querySelector('#deal-pulse-header');

    let maxHeaderLength = 0;
    let maxBodyLength = 0;

    offerCards?.forEach((offerCard) => {
        const header = offerCard.querySelector('.offer-card-header');
        const body = offerCard.querySelector('.offer-card-container');

        maxHeaderLength = Math.max(maxHeaderLength, header?.clientHeight || 0);
        maxBodyLength = Math.max(maxBodyLength, body?.clientHeight || 0);
    });

    offerCards?.forEach((offerCard) => {
        const header = offerCard.querySelector('.offer-card-header');
        const body = offerCard.querySelector('.offer-card-container');

        header?.setAttribute('style', `height: ${maxHeaderLength}px`);
        body?.setAttribute('style', `height: ${maxBodyLength}px`);
    });

    dealPulseSeparator?.setAttribute('style', `height: ${maxBodyLength}px`);
    dealPulseHeader?.setAttribute('style', `height: ${maxHeaderLength}px`);
    dealPulseContainer?.setAttribute('style', `height: ${maxBodyLength}px`);
};
