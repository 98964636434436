import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { TermMonthItem, FINANCE } from '@makemydeal/dr-platform-types';
import { GridItem, StyledTypography } from './styled.components';
import { TermMonthInput } from './TermMonthInput';

export const TermsMonth = ({ paymentGridTermList }: { paymentGridTermList: TermMonthItem[] }) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    return (
        <>
            <GridItem
                xs={3}
                className="header"
                sx={{
                    background: '#f4f4f4'
                }}
            >
                <StyledTypography className="large" data-testid="cash-down-header">
                    {offerType === FINANCE ? 'Cashdown' : 'Due at Signing'}
                </StyledTypography>
            </GridItem>
            {paymentGridTermList.map((term) => (
                <TermMonthInput key={`${term.key}-${term.val}`} term={term} />
            ))}
        </>
    );
};
