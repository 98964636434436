// externals
import React, { useMemo } from 'react';
import { StateTree } from '@makemydeal/dr-dash-types';

// hooks
import { useSelector } from 'react-redux';

// selectors
import { dealLifecycleSelectors, dealXgDetailsSelectors, shortDateString } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components
import { Badge } from '@interstate/components/Badge';
import { Button } from '@interstate/components/Button';
import { DealLifecycleModal } from './DealLifecycleModal.interstate';
import { Typography } from '@interstate/components/Typography';

// constants
import { SALE, DELIVERED, CONTRACT_SIGNED, APPROVED, SOLD } from '../constants';

// styles
import { DashboardDealDate, DashboardLifecycleContainer } from './DealDatesAndStatus.style';

type BadgeVariant = 'success' | 'error' | 'info';

export const DealDatesAndStatus = () => {
    const state = useSelector((state: StateTree) => state);
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const { getDealLifecycle } = dealLifecycleSelectors;
    const dealLifecycle = getDealLifecycle(state);
    const status = dealLifecycle.status || '';
    const { soldDate, approvedDate, contractSignedDate, deliveredDate } = dealLifecycle;
    const [showModal, setShowModal] = React.useState(false);

    const badgeVariant: BadgeVariant = useMemo(
        () => (status === 'Lost' ? 'error' : status === 'Sold' ? 'success' : 'info'),
        [status]
    );
    const isEnableRelaxSoldState = useSelector(featureToggleSelectors.enableRelaxSoldState);

    const getDashboardDate = () => {
        if ((status.toLowerCase() === 'inprogress' || status.toLowerCase() === 'lost') && soldDate && !deliveredDate) {
            if (!contractSignedDate) {
                const saleText = `${SALE}: ${shortDateString(new Date(soldDate))}`;
                return saleText;
            } else {
                return `${CONTRACT_SIGNED}: ${shortDateString(new Date(contractSignedDate))}`;
            }
        } else if (
            (status.toLowerCase() === 'inprogress' || status.toLowerCase() === 'sold' || status.toLowerCase() === 'lost') &&
            deliveredDate
        ) {
            return `${DELIVERED}: ${shortDateString(new Date(deliveredDate))}`;
        }

        return '';
    };

    const getDashboardDateForRelaxSoldStateFF = () => {
        if (deliveredDate) {
            return `${DELIVERED}: ${shortDateString(new Date(deliveredDate))}`;
        } else if (contractSignedDate) {
            return `${CONTRACT_SIGNED}: ${shortDateString(new Date(contractSignedDate))}`;
        } else if (approvedDate) {
            return `${APPROVED}: ${shortDateString(new Date(approvedDate))}`;
        } else if (soldDate) {
            return `${SOLD}: ${shortDateString(new Date(soldDate))}`;
        }
        return '';
    };

    return (
        <div data-testid="dr-dash-deal-status-container">
            <DashboardLifecycleContainer>
                {isEcommerceDeal && isABPencilEnabled && (
                    <Badge data-testid="is-ecommerce-deal" variant="info" sx={{ marginRight: '1.3rem' }}>
                        <span>Order from Ecommerce</span>
                    </Badge>
                )}
                <Badge variant={badgeVariant} data-testid="deal-status-value">
                    <span>Deal {status.toLowerCase() === 'inprogress' ? 'In Progress' : status}</span>
                </Badge>
                <Button data-testid="edit-btn" buttonStyle="tertiary" id="edit-btn" onClick={() => setShowModal(true)}>
                    <Typography variant="anchor-block-md" color="sem.color.on-surface.link.rest">
                        Edit
                    </Typography>
                </Button>
            </DashboardLifecycleContainer>
            <DashboardDealDate data-testid="deal-date">
                {isEnableRelaxSoldState ? getDashboardDateForRelaxSoldStateFF() : getDashboardDate()}
            </DashboardDealDate>
            <DealLifecycleModal show={showModal} setShowModal={setShowModal} dealLifecycle={dealLifecycle} />
        </div>
    );
};
