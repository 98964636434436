// externals
import { useSelector } from 'react-redux';

// components
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// libraries
import {
    accessoriesSelectors,
    compositeSelectors,
    offerReduxSelectors,
    offerSelectors,
    vehicleProtectionSelectors
} from '@makemydeal/dr-dash-store';
import type { DealHistory } from '@makemydeal/dr-dash-types';

// types
import type { LineItem } from '../../types/LineItemTypes';

// constants
import * as constants from '../../constants';

// feature toggles
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// utils
import {
    convertAccessoryToLineItem,
    convertDealerFeeToLineItem,
    convertGovernmentFeeToLineItem,
    convertVppProductToLineItem,
    getCappedFeeBreakdown,
    getStrAmountsWithoutSymbols,
    lineItemWithChildrenAdaptor
} from '../utils/transform';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import { EnhancedDealSummaryItemWrapper } from '../common/EnhancedDealSummaryItemWrapper';
import LineItemWithChildren from '../common/LineItemWithChildren';
import LineItems from '../common/LineItems';

import { Grid } from '@interstate/components/Grid';
import { vehicleProtectionUtils } from '@makemydeal/dr-dash-store';
import { kebabCase } from 'lodash';
import { useMemo } from 'react';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';
import { EnhancedDealSummaryWrapper } from '../../constants';

export type AddsToCapCostProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const AddsToCapCost = ({ deal, useWrapper = true, hasDelta = false }: AddsToCapCostProps) => {
    const useDealRootSelector = createDealRootSelector(deal);

    // add to cap cost
    const addsToCapCostTotal = useDealRootSelector(compositeSelectors.getAddsToCapCostTotal);
    const addsToCapCostTotalAmount = useSelector(offerReduxSelectors.getAddsToCapCostTotal);

    // accessories
    const accessoriesTotal = useDealRootSelector(accessoriesSelectors.getCapAccessoriesTotal);
    const itemizedAccessories = useDealRootSelector(accessoriesSelectors.getCapAccessoriesList).map(convertAccessoryToLineItem);
    const itemizedAccessoriesDisplay = lineItemWithChildrenAdaptor(getStrAmountsWithoutSymbols(itemizedAccessories), {
        key: 'label',
        label: 'label',
        value: 'value'
    });

    // taxes
    // TODO: change addsToCapCostTotalAmount to addsToCapCostTotal when removing feature toggle gate
    // TODO: getCapitalizedTaxTotal uses current prop that returns value - getCapitalizedTaxes dependent on PS and not implemented yet
    // const capitalizedTaxTotal = useSelector(offerReduxSelectors.getCapitalizedTaxTotal);
    const capitalizedTaxTotal = useSelector(offerReduxSelectors.getCapitalizedTaxes);
    const getFlatOrItemizedTaxes = useSelector(offerSelectors.getFlatOrItemizedTaxes);
    const capitalizedTaxesDisplay = lineItemWithChildrenAdaptor(getFlatOrItemizedTaxes, {
        key: 'taxCode',
        label: 'taxName',
        value: 'taxAmount'
    });

    // fees
    const dealerFeeTotal = useDealRootSelector(offerSelectors.getCappedDealerFeesTotal);
    const itemizedDealerFees = useDealRootSelector(offerSelectors.getDealerFees);
    const dealerFeeChildren = useMemo(() => {
        const { cappedFees } = getCappedFeeBreakdown(itemizedDealerFees);

        return cappedFees.map(convertDealerFeeToLineItem);
    }, [itemizedDealerFees]);

    const capitalizedLenderFeesTotal = useSelector(offerReduxSelectors.getCapitalizedLenderFees);
    const itemizedLenderFees = useSelector(offerSelectors.getIncludedCappedLenderFees);
    const itemizedLenderFeesDisplay = lineItemWithChildrenAdaptor(itemizedLenderFees, {
        key: 'dealerFeeTypeCode',
        label: 'dealerFeeName',
        value: 'dealerFeeAmount'
    });

    const capitalizedGovFees = useSelector(offerReduxSelectors.getCapitalizedGovFees);
    const itemizedGovFees = useSelector(offerSelectors.getIncludedCappedGovernmentFees);
    const itemizedGovFeesDisplay = lineItemWithChildrenAdaptor(itemizedGovFees, {
        key: 'code',
        label: 'name',
        value: 'amount'
    });

    const capitalizedDealerFees = useSelector(offerReduxSelectors.getCapitalizedDealerFees);
    const dealerFeesItemized = useSelector(offerSelectors.getIncludedCappedDealerFees);
    const dealerFeesItemizedDisplay = lineItemWithChildrenAdaptor(dealerFeesItemized, {
        key: 'dealerFeeTypeCode',
        label: 'dealerFeeName',
        value: 'dealerFeeAmount'
    });

    const govermentFee = useDealRootSelector(offerReduxSelectors.getGovernmentFees);
    const { cappedFeeTotal, fee } = govermentFee;
    const govFeeChildren: LineItem[] = useMemo(() => {
        return fee.taxFee.filter((fee) => fee.capped && fee.amount > 0).map(convertGovernmentFeeToLineItem);
    }, [fee]);

    // protection products
    const protectionProductsTotal = useDealRootSelector(vehicleProtectionSelectors.getCapVppTotal);
    const vppProducts = useDealRootSelector(vehicleProtectionSelectors.getVppProducts);
    const protectionProductsItems = useMemo(
        () =>
            vppProducts
                .filter(vehicleProtectionUtils.isVppProductSelected)
                .filter(vehicleProtectionUtils.isVppCapProduct)
                .map(convertVppProductToLineItem),
        [vppProducts]
    );
    const protectionProductsDisplay = lineItemWithChildrenAdaptor(getStrAmountsWithoutSymbols(protectionProductsItems), {
        key: 'label',
        label: 'label',
        value: 'value'
    });

    // prior loan balance
    const priorLoanBalance = useSelector(offerReduxSelectors.getPriorLoanBalance);

    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    const DealSummaryItems = [
        {
            label: constants.CAPITALIZED_TAXES,
            value: capitalizedTaxTotal,
            children: capitalizedTaxesDisplay
        },
        {
            label: constants.LENDER_FEES,
            value: capitalizedLenderFeesTotal,
            children: itemizedLenderFeesDisplay
        },
        {
            label: constants.GOVERNMENT_FEES,
            value: capitalizedGovFees,
            children: itemizedGovFeesDisplay
        },
        {
            label: constants.DEALER_FEES,
            value: capitalizedDealerFees,
            children: dealerFeesItemizedDisplay
        },
        {
            label: constants.PROTECTION_PRODUCTS,
            value: protectionProductsTotal,
            children: protectionProductsDisplay
        },
        {
            label: constants.ACCESSORIES,
            value: accessoriesTotal,
            children: itemizedAccessoriesDisplay
        }
    ];

    if (priorLoanBalance > 0) {
        DealSummaryItems.push({
            label: constants.NEGATIVE_TRADE_EQUITY,
            value: priorLoanBalance,
            children: []
        });
    }

    function renderDealSummaryItems(
        items: {
            label?: string;
            value?: number;
            children: { key: string; label: string; value: string }[];
        }[]
    ) {
        return items.map(
            (
                {
                    /* istanbul ignore next: fallback */
                    label = '',
                    /* istanbul ignore next: fallback */
                    value = 0,
                    children
                },
                i: number
            ) => (
                <EnhancedDealSummaryItemWrapper
                    key={`${label}-${i}`}
                    data-testid={`${kebabCase(label)}-EnhancedDealSummaryItemWrapper`}
                    label={label}
                    value={formatDollarsAndCents(value)}
                    useWrapper={useWrapper}
                    hasDelta={hasDelta}
                    prChildren={EnhancedDealSummaryWrapper.Children_Right_Padding}
                    labelTypography={EnhancedDealSummaryWrapper.Label_Typography}
                    valueTypography={EnhancedDealSummaryWrapper.Level_Two_Value_Typography}
                >
                    {children.length > 0 && <LineItems collection={children} />}
                </EnhancedDealSummaryItemWrapper>
            )
        );
    }

    if (isEnhancedDealDetailsEnabled) {
        return (
            <EnhancedDealSummaryItemWrapper
                data-testid={`${kebabCase(constants.ADDS_TO_CAP_COST)}-EnhancedDealSummaryItemWrapper`}
                label={constants.ADDS_TO_CAP_COST}
                value={formatDollarsAndCents(addsToCapCostTotalAmount)}
                useWrapper={useWrapper}
                hasDelta={hasDelta}
                paddingY={12}
                pr={16}
                plChildren={EnhancedDealSummaryWrapper.Children_Left_Padding}
                labelTypography={EnhancedDealSummaryWrapper.Label_Typography}
                valueTypography={EnhancedDealSummaryWrapper.Level_One_Value_Typography}
            >
                {renderDealSummaryItems(DealSummaryItems)}
            </EnhancedDealSummaryItemWrapper>
        );
    }

    return (
        <DealSummaryItemWrapper
            label={constants.ADDS_TO_CAP_COST}
            value={formatDollarsAndCents(addsToCapCostTotal)}
            useWrapper={useWrapper}
            hasDelta={hasDelta}
        >
            <Grid>
                <LineItemWithChildren
                    label={constants.ACCESSORIES}
                    value={formatDollarsAndCents(accessoriesTotal)}
                    childItems={itemizedAccessories}
                />
                <LineItemWithChildren
                    label={constants.DEALER}
                    value={formatDollarsAndCents(dealerFeeTotal)}
                    childItems={dealerFeeChildren}
                />
                <LineItemWithChildren
                    label={constants.GOV_FEES}
                    value={formatDollarsAndCents(cappedFeeTotal)}
                    childItems={govFeeChildren}
                />
                <LineItemWithChildren
                    label={constants.PROTECTION}
                    value={formatDollarsAndCents(protectionProductsTotal)}
                    childItems={protectionProductsItems}
                />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default AddsToCapCost;
