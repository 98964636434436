import { Badge } from '@interstate/components/Badge';
import { Box } from '@interstate/components/Box';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';
import { toRem } from '../utils/pixelUtils';

export const OfferCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .offer-card-container {
        box-sizing: border-box;
        border: 1px solid #cacfd9;
        border-radius: 0.3rem;
        background-color: #ffffff;
        box-shadow: 0 0.145rem 0.3rem 0 rgba(0, 0, 0, 0.05);
        width: 19.3rem;
        margin-bottom: 1.8rem;
        position: relative;

        @media (max-width: ${BreakPoint.TABLET_PORTRAIT}) {
            width: 16.8rem;
            height: 16.4rem;
            margin: 0 auto;
        }

        .panel-body {
            flex-direction: column;
        }
        .card-media-header-content-container {
            height: 75%;
        }
        .offer-card-compare-check-box {
            padding: 0.8rem 0 1rem 1.8rem;
        }
    }

    @media (max-width: ${BreakPoint.SMALL}) {
        margin: 0 auto 1.5rem;
    }
`;

export const OfferBadge = styled(Badge)`
    position: absolute;
    right: 1rem;
`;

export const StyledOfferCardWrapper = styled(Box)`
    width: ${toRem(320)};
    min-width: ${toRem(320)};

    &.offer-card__wide {
        width: ${toRem(352)};
    }

    .offer-card-header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
`;

export const StyledABPencilOfferCardContainer = styled.div`
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};

    .offer-card-container {
        display: flex;
        flex-direction: column;

        .offer-card-body {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            border: ${({ theme }) => `${theme.tokens.BaseSizeBorderPx} solid ${theme.tokens.SemColorBorderMuted}`};
            border-radius: 0.3rem;

            .content-wrapper {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: ${toRem(16)};
                padding: ${toRem(16)};
                padding-top: ${toRem(8)};

                .header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .badge-container {
                        display: flex;
                        align-items: center;
                        gap: ${toRem(8)};

                        .badge-scenario-count {
                            color: ${({ theme }) => theme.tokens.BaseColorBlue700};
                            border-color: currentColor !important;
                        }
                    }
                }

                .content {
                    flex-grow: 1;
                }

                .message {
                    // this hardocded height is to make sure message areas are aligned horizontally. Even they are empty.
                    // And also give them a breathing room.
                    height: ${toRem(26)};
                    display: flex;
                    align-items: center;
                }
            }

            .actions {
                border-top: ${({ theme }) => `${theme.tokens.BaseSizeBorderPx} solid ${theme.tokens.SemColorBorderMuted}`};
                padding: ${toRem(10)} ${toRem(16)};
                display: flex;
                align-items: center;
                gap: ${toRem(16)};
            }

            .actions-shrink {
                height: ${toRem(30)};
            }
        }

        .stack-container {
            display: grid;
            justify-items: center;

            .stack-1,
            .stack-2 {
                height: ${toRem(5)};
                border: ${({ theme }) => `${theme.tokens.BaseSizeBorderPx} solid ${theme.tokens.SemColorBorderMuted}`};
                border-top: none;
                border-bottom-left-radius: 0.3rem;
                border-bottom-right-radius: 0.3rem;
            }

            .stack-1 {
                width: calc(100% - ${toRem(10)}) !important;
            }
            .stack-2 {
                width: calc(100% - ${toRem(20)}) !important;
            }
        }
    }
`;
