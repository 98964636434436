// externals
import { Reducer } from 'redux';

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { DeskingGlobalState } from '@makemydeal/dr-dash-types';

import {
    PAYMENT_DETAILS_ACCORDION_TOGGLE,
    FEES_ACCORDION_TOGGLE,
    TAXES_ACCORDION_TOGGLE
} from '../actionTypes/deskingGlobalActionTypes';

export type DeskingGlobalReducer = Reducer<DeskingGlobalState>;

const initialState: DeskingGlobalState = {
    paymentDetailsAccordionExpanded: false,
    taxesAccordionExpanded: false,
    feesAccordionExpanded: false
};

export const reducer: DeskingGlobalReducer = (state = initialState, action: AnyFSA) => {
    switch (action.type) {
        case PAYMENT_DETAILS_ACCORDION_TOGGLE: {
            return {
                ...state,
                paymentDetailsAccordionExpanded: !state.paymentDetailsAccordionExpanded
            };
        }
        case TAXES_ACCORDION_TOGGLE: {
            return {
                ...state,
                taxesAccordionExpanded: !state.taxesAccordionExpanded
            };
        }
        case FEES_ACCORDION_TOGGLE: {
            return {
                ...state,
                feesAccordionExpanded: !state.feesAccordionExpanded
            };
        }
    }
    return state;
};
