// libraries
import { Grid } from '@interstate/components/Grid';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { compositeSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';

// consts/enums
import { CAP_COST_REDUCTION, CASH, REBATES, TRADE_IN } from '../../constants';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import { QuickCompareOffer } from '../../offerCompareColumn/types/quickCompareOffer';
import LineItemWithChildren from '../common/LineItemWithChildren';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

export interface ICapCostReductionProps {
    offer?: QuickCompareOffer;
    useWrapper?: boolean;
}

const CapCostReduction = ({ offer, useWrapper = true }: ICapCostReductionProps) => {
    const useDealRootSelector = createDealRootSelector(offer ? { offer } : undefined);

    const netCashDown = useDealRootSelector(offerReduxSelectors.getNetCashDown);
    const reductionRebate = useDealRootSelector(offerReduxSelectors.getCapReductionRebate);
    const reductionTradeIn = useDealRootSelector(offerReduxSelectors.getCapReductionTradeIn);
    const selectedCapCostReduction = useDealRootSelector(compositeSelectors.getCapCostReduction);

    return (
        <DealSummaryItemWrapper
            label={CAP_COST_REDUCTION}
            value={formatDollarsAndCents(selectedCapCostReduction)}
            useWrapper={useWrapper}
        >
            <Grid>
                <LineItemWithChildren label={CASH} value={formatDollarsAndCents(netCashDown)} />
                <LineItemWithChildren label={REBATES} value={formatDollarsAndCents(reductionRebate)} />
                <LineItemWithChildren label={TRADE_IN} value={formatDollarsAndCents(reductionTradeIn)} />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default CapCostReduction;
