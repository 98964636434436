// externals
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';

// libraries
import { easing } from '@makemydeal/dr-activities-common';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxSelectors, offerSelectors, offerAdditionalSelectors } from '@makemydeal/dr-dash-store';
import { CASH, FINANCE } from '@makemydeal/dr-platform-types';

// consts/enums
import { CASH_DOWN, NOT_AVAILABLE, OUT_OF_POCKET } from '../constants';

// styles
import { Typography, TypographyProps } from '@interstate/components/Typography';
import { DataContainer, DealData, DealSummary, DealSummaryContainer } from './DealInfo.style.interstate';

const wrappedTypographyComponent = (props: TypographyProps) => <Typography {...props}>{props.children}</Typography>;
const AnimatedTypography = animated(wrappedTypographyComponent);

const DealInfoInterstate = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const totatDueAtSigning = useSelector(offerReduxSelectors.getDueAtSigning);
    const cashDownFinance = useSelector(offerReduxSelectors.getNetCashDown);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const paymentNotAvailable = useSelector(offerSelectors.isPaymentUnavailable);
    const isUsePSTotalAmountDueForCashDealsEnabled = useSelector(featureToggleSelectors.isUsePSTotalAmountDueForCashDealsEnabled);
    // old logic - calculation - needs to be replaced by getCashTotalAmountDueAfterCredits
    let cashOffer = useSelector((state) => offerAdditionalSelectors.getMvCashOfferRoundedUp(state as any, true));
    // new logic - read PS field directly
    const totalAmountDueAfterCredits = useSelector(offerReduxSelectors.getCashTotalAmountDueAfterCredits);

    const cashOfferFormatter = formatDollarsAndCents;
    if (isUsePSTotalAmountDueForCashDealsEnabled) {
        cashOffer = totalAmountDueAfterCredits;
    }
    const offerPrice = useSelector(offerSelectors.getSellingPrice);

    let paymentInfo: any;
    if (currentOfferType === CASH) {
        paymentInfo = {
            sellingPrice: 0,
            cashDown: 0,
            dueAtSigning: 0,
            payment: 0,
            price: cashOffer ?? 0
        };
    } else if (currentOfferType === FINANCE) {
        paymentInfo = {
            sellingPrice: offerPrice ?? 0,
            cashDown: cashDownFinance ?? 0,
            dueAtSigning: 0,
            payment: monthlyPayment ?? 0,
            price: 0
        };
    } else {
        paymentInfo = {
            sellingPrice: offerPrice ?? 0,
            cashDown: 0,
            dueAtSigning: totatDueAtSigning ?? 0,
            payment: monthlyPayment ?? 0,
            price: 0
        };
    }

    const isCashOffer = currentOfferType === CASH;
    const isFinanceOffer = currentOfferType === FINANCE;

    const { price, sellingPrice, cashDown, dueAtSigning, payment } = paymentInfo;

    const cashPriceSpring: any = useSpring({
        from: { val: price },
        to: { val: price },
        config: { friction: 50, precision: 5.0, clamp: true, easing }
    });

    const sellingPriceSpring: any = useSpring({
        from: { val: sellingPrice },
        to: { val: sellingPrice },
        config: { friction: 50, precision: 5.0, clamp: true, easing }
    });

    const monthlyPaymentSpring: any = useSpring({
        from: { val: payment },
        to: { val: payment },
        config: { friction: 50, precision: 5.0, clamp: true, easing }
    });

    const cashDownSpring: any = useSpring({
        from: { val: cashDown },
        to: { val: cashDown },
        config: { friction: 50, precision: 5.0, clamp: true, easing }
    });

    const dueAtSigningSpring: any = useSpring({
        from: { val: dueAtSigning },
        to: { val: dueAtSigning },
        config: { friction: 50, precision: 5.0, clamp: true, easing }
    });

    if (isCashOffer) {
        return (
            <DealSummary>
                <Typography variant="h3" tag="div" sx={{ textAlign: 'end' }}>
                    Recent Offer
                </Typography>
                <DealData>
                    <Typography
                        data-testid="selling-price-label"
                        variant="label-md"
                        color="base.color.gray.600"
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        Total Amount Due
                    </Typography>

                    <div data-testid="header_dealinfo_total_due">
                        <AnimatedTypography variant="body-md" className="full-cash-payment" data-testid="springCashPrice">
                            {cashPriceSpring.val.interpolate(cashOfferFormatter)}
                        </AnimatedTypography>
                    </div>
                </DealData>
            </DealSummary>
        );
    } else {
        return (
            <DealSummary data-testid="deal-info-section">
                <Typography variant="h3" tag="div" sx={{ textAlign: 'end' }}>
                    Recent Offer
                </Typography>
                <DealSummaryContainer>
                    <DealData>
                        <Typography
                            data-testid="selling-price-label"
                            variant="label-md"
                            tag="div"
                            color="base.color.gray.600"
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            Selling Price
                        </Typography>
                        <DataContainer>
                            <div data-testid="header_dealinfo_selling_price">
                                {paymentNotAvailable ? (
                                    <Typography variant="body-md" tag="div">
                                        $--
                                    </Typography>
                                ) : (
                                    <AnimatedTypography variant="body-md" tag="div" data-testid="springSellingPrice">
                                        {sellingPriceSpring.val.interpolate(formatDollarsAndCents)}
                                    </AnimatedTypography>
                                )}
                            </div>
                        </DataContainer>
                    </DealData>
                    <DealData>
                        <Typography
                            data-testid="cash-signing-label"
                            variant="label-md"
                            color="base.color.gray.600"
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            {isFinanceOffer ? CASH_DOWN : OUT_OF_POCKET}
                        </Typography>
                        <DataContainer>
                            {paymentNotAvailable ? (
                                <Typography variant="body-md" tag="div">
                                    {NOT_AVAILABLE}
                                </Typography>
                            ) : (
                                <div>
                                    <AnimatedTypography variant="body-md" tag="div" data-testid="header_dealinfo_cash_signing">
                                        {isFinanceOffer
                                            ? cashDownSpring.val.interpolate(formatDollarsAndCents)
                                            : dueAtSigningSpring.val.interpolate(formatDollarsAndCents)}
                                    </AnimatedTypography>
                                </div>
                            )}
                        </DataContainer>
                    </DealData>
                    <DealData>
                        <Typography
                            data-testid="payment-label"
                            variant="label-md"
                            color="base.color.gray.600"
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            Payment
                        </Typography>
                        <DataContainer>
                            <div data-testid="header_dealinfo_monthly_payment">
                                {paymentNotAvailable ? (
                                    <Typography variant="body-md" tag="div">
                                        $--
                                    </Typography>
                                ) : (
                                    <AnimatedTypography variant="body-md" tag="div" data-testid="monthlyPaymentSpring">
                                        {monthlyPaymentSpring.val.interpolate(formatDollarsAndCents)}
                                    </AnimatedTypography>
                                )}
                            </div>
                            <Typography variant="body-md" tag="div">
                                /mo*
                            </Typography>
                        </DataContainer>
                    </DealData>
                </DealSummaryContainer>
            </DealSummary>
        );
    }
};

export default DealInfoInterstate;
