import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OPEN_IN_DARWIN_FLOW, pushToDarwinActionCreators, pushToDarwinSelectors } from '@makemydeal/dr-dash-store';
import { PushToDarwinStatus } from '@makemydeal/dr-dash-types';

import { Modal } from '@interstate/components/Modal';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import Button from '@cx/ui/Button';
import { Typography } from '@interstate/components/Typography';

import { LoaderWrapper, StyledAlert, StyledButton, StyledPushToDarwinFooter } from './pushToDarwinLoadingPopup.style';

export interface IPushToDarwinPopupProps {
    onHide?(): void;
}

const PUSH_TO_DARWIN_SUCCESS_MESSAGE = 'Transaction successfully created';
const prefix = 'push-to-darwin';

const PushToDarwinPopup = (props: IPushToDarwinPopupProps) => {
    const pushToDarwinStatus = useSelector(pushToDarwinSelectors.getPushToDarwinStatus);
    const pushToDarwinCurrentFlow = useSelector(pushToDarwinSelectors.getPushToDarwinCurrentFlow);
    const isOpenDarwinUrlFlow = pushToDarwinCurrentFlow === OPEN_IN_DARWIN_FLOW;

    const [showPopup, setShowPopup] = useState(false);
    const dispatch = useDispatch();

    const isLoading = pushToDarwinStatus === PushToDarwinStatus.Sending;
    const isFailure = pushToDarwinStatus === PushToDarwinStatus.Failure;
    const isSuccess = pushToDarwinStatus === PushToDarwinStatus.Ok;

    const errorDetails = useSelector(pushToDarwinSelectors.getDarwinErrorDetails);

    useEffect(() => {
        setShowPopup(pushToDarwinStatus !== PushToDarwinStatus.Ready);
    }, [pushToDarwinStatus]);

    if (!showPopup) {
        return null;
    }

    const handleHidePopup = () => {
        props.onHide?.();
        setShowPopup(false);
        dispatch(pushToDarwinActionCreators.setPushToDarwinStatus(PushToDarwinStatus.Ready));
    };

    const handleClosePopup = () => {
        if (isOpenDarwinUrlFlow) {
            dispatch(pushToDarwinActionCreators.openInDarwinUrl());
        }
        handleHidePopup();
    };

    const alertContent = (
        <StyledAlert
            disableSuccessFade
            data-testid={`${prefix}-alert-${isSuccess ? 'success' : 'error'}`}
            type={isSuccess ? 'success' : 'error'}
            role=""
        >
            {isSuccess && <Typography variant="h6">Sent to Darwin</Typography>}
            {isFailure && (
                <Typography variant="h6" sx={{ textTransform: 'none' }} data-testid={`${prefix}-alert-title`}>
                    {isOpenDarwinUrlFlow ? 'Unable to open Darwin' : 'Unable to push to Darwin'}
                </Typography>
            )}
            <Typography variant="body-sm" tag="span" data-testid={`${prefix}-alert-message`}>
                {isSuccess ? PUSH_TO_DARWIN_SUCCESS_MESSAGE : errorDetails}
            </Typography>
        </StyledAlert>
    );

    const loadingContent = (
        <>
            <LoaderWrapper>
                <LoadingIndicator message="We're updating your changes to this offer." size="small" />
            </LoaderWrapper>
        </>
    );

    const footer = !isLoading && (
        <StyledPushToDarwinFooter data-testid={`${prefix}-footer`}>
            {isOpenDarwinUrlFlow && !isFailure && (
                <StyledButton
                    htmlId={`${prefix}-close-button`}
                    data-testid={`${prefix}-go-to-darwin-button`}
                    buttonStyle="primary"
                    onClick={handleClosePopup}
                >
                    Go To Darwin
                </StyledButton>
            )}
            <Button
                htmlId={`${prefix}-close-button`}
                data-testid={`${prefix}-close-button`}
                buttonStyle="primary"
                onClick={handleHidePopup}
            >
                Close
            </Button>
        </StyledPushToDarwinFooter>
    );

    const renderContent = () => {
        if (isLoading) {
            return loadingContent;
        }
        if (isSuccess || isFailure) {
            return alertContent;
        }
        return null;
    };

    return (
        <Modal
            data-testid={prefix + '-popup'}
            show={showPopup}
            onHide={handleHidePopup}
            header={<h3 data-testid={prefix + '-popup-header'}>{isOpenDarwinUrlFlow ? 'Open in Darwin' : 'Push to Darwin'}</h3>}
            size="large"
            footer={{ footerComponent: footer }}
        >
            {renderContent()}
        </Modal>
    );
};
export default PushToDarwinPopup;
