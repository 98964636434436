import { Grid } from '@interstate/components/Grid';
import { GridRow } from './GridRow';
import { TermsMonth } from './TermsMonth';
import { Override } from './Override';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { PaymentGridRow, TermMonthItem } from '@makemydeal/dr-platform-types';
import { useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { FC } from 'react';

export const GridTableInterstate: FC = () => {
    const paymentGridItems: PaymentGridRow[] = useSelector(offerReduxSelectors.getPaymentGridItems);
    const paymentGridTermList: TermMonthItem[] = useSelector(offerReduxSelectors.getPaymentGridTermsList);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <Grid
            data-testid="payment-grid"
            container
            justifyContent="center"
            alignItems="center"
            marginTop="20px"
            padding={isABPencilEnabled ? '0 20px' : '0 8px'}
            sx={{
                '& > div:first-of-type': {
                    borderTopLeftRadius: '0.2rem'
                },
                '& > div:nth-of-type(4)': {
                    borderTopRightRadius: '0.2rem'
                }
            }}
        >
            <TermsMonth paymentGridTermList={paymentGridTermList} />
            {paymentGridItems.map((item) => (
                <Grid
                    key={item.key}
                    display="contents"
                    sx={{
                        '& > div:first-of-type': {
                            background: '#f4f4f4'
                        }
                    }}
                >
                    <GridRow data={item} />
                </Grid>
            ))}
            <Override />
        </Grid>
    );
};
