/* eslint-disable react/no-multi-comp */
// TODO: use centralized component between here and activities-desking
import { ReactNode, useState } from 'react';

// components
import { ChevronDownIcon } from '@interstate/components/Icons/ChevronDownIcon';
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';

// constants
import { ZERO_DOLLARS } from '../../constants';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography, TypographyVariant } from '@interstate/components/Typography';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { ExpandableArea, ExpandableAreaProvider } from '../../common/ExpandableArea';
import { StyledExpandableAreaSection, StyledListItem } from './DealSummaryItem.style';

export interface IDealSummaryItemProps {
    label: string;
    value: string;
    children?: ReactNode;
    /**
     * @deprecated Unused prop
     */
    showLargeValue?: boolean;
    isEmpty?: boolean;
    /**
     * @deprecated Unused prop
     */
    isExpanded?: boolean;
    hasDelta?: boolean;
    paddingY?: number;
    pr?: number;
    prChildren?: number;
    plChildren?: number;
    labelTypography?: TypographyVariant;
    valueTypography?: TypographyVariant;
}
const LineItemRow = ({ children, pl, paddingY, pr }: { children: ReactNode; pl: string; paddingY?: number; pr?: number }) => (
    <Grid container justifyContent="space-between" alignItems="center" paddingY={`${paddingY}px`} pr={`${pr}px`} pl={pl}>
        {children}
    </Grid>
);

export const EnhancedDealSummaryItem = ({
    label,
    value,
    children,
    isEmpty = false,
    hasDelta = false,
    paddingY,
    pr = 0,
    prChildren = 2,
    plChildren = 36,
    labelTypography = 'body-sm',
    valueTypography = 'strong-md'
}: IDealSummaryItemProps) => {
    const dealSummaryItemPrefix = 'deal-summary-item';
    const kebabLabel = kebabCase(label);
    const containerTestId = `${dealSummaryItemPrefix}-${kebabLabel}`;
    const labelId = `${dealSummaryItemPrefix}-label-${kebabLabel}`;
    const valueId = `${dealSummaryItemPrefix}-value-${kebabLabel}`;
    const className = isEmpty ? 'empty-item' : '';

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    if (value !== ZERO_DOLLARS && children) {
        return (
            <StyledListItem className={`${className} ${hasDelta ? 'has-delta' : ''}`} hasDelta={hasDelta}>
                <ExpandableAreaProvider>
                    <StyledExpandableAreaSection expandableId="deal-summary-item-accordion">
                        <div onClick={handleToggle} data-testid="deal-summary-item-accordion-toggle">
                            <LineItemRow pl="8px" paddingY={paddingY} pr={pr}>
                                {expanded ? (
                                    <ChevronDownIcon data-testid="chevron-down-icon" className="chevron-icon" />
                                ) : (
                                    <ChevronRightIcon data-testid="chevron-right-icon" className="chevron-icon" />
                                )}

                                <Typography
                                    tag="span"
                                    sx={{
                                        flexGrow: 1
                                    }}
                                    variant={labelTypography}
                                    color="base.color.blue.700"
                                    data-testid={containerTestId}
                                >
                                    {label}
                                </Typography>
                                <Typography
                                    tag="span"
                                    variant={valueTypography}
                                    color="sem.color.on-surface.default"
                                    data-testid={valueId}
                                >
                                    {value}
                                </Typography>
                            </LineItemRow>
                        </div>
                    </StyledExpandableAreaSection>
                    <ExpandableArea data-testid="deal-summary-item-accordion" expandableId="deal-summary-item-accordion">
                        <Grid pb={2} pr={prChildren} pl={`${plChildren}px`}>
                            {children}
                        </Grid>
                    </ExpandableArea>
                </ExpandableAreaProvider>
            </StyledListItem>
        );
    }

    return (
        <StyledListItem data-testid={containerTestId} hasDelta={hasDelta} className={`${className} ${hasDelta ? 'has-delta' : ''}`}>
            <LineItemRow pl="26px" pr={pr} paddingY={paddingY}>
                <Typography tag="span" variant={labelTypography} color="base.color.blue.700" data-testid={labelId}>
                    {label}
                </Typography>
                <Typography tag="span" variant={valueTypography} color="sem.color.on-surface.default" data-testid={valueId}>
                    {value}
                </Typography>
            </LineItemRow>
        </StyledListItem>
    );
};

export default EnhancedDealSummaryItem;
