// externals
import { useSelector } from 'react-redux';

// selectors
import { featureToggleSelectors, RawStateProvider } from '@makemydeal/dr-shared-store';
import { DeskingActivityDealSingle } from './DeskingActivityDealSingle';
import { DeskingActivityDealMultipleWrapper } from './DeskingActivityDealMultipleWrapper';

export const DeskingActivityDeal = () => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    if (isABPencilEnabled) {
        return (
            <RawStateProvider>
                <DeskingActivityDealMultipleWrapper />
            </RawStateProvider>
        );
    }

    return <DeskingActivityDealSingle />;
};
