import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TermMonthItem } from '@makemydeal/dr-platform-types';
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { NumericInput } from '@interstate/components/NumericInput';
import { GridItem } from './styled.components';

export const TermMonthInput = ({ term }: { term: TermMonthItem }) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const [termValue, setTermValue] = useState('');

    useEffect(() => {
        setTermValue(`${term.val ?? ''}`);
    }, [term]);

    const handleChange = (e: InterstateOnChangeEvent<TextInputEventValue>) => setTermValue(e.target.value);

    const handleBlur = (item: TermMonthItem) => (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        const value = e.target.value === '' ? null : parseInt(e.target.value);
        if (item.val === value) return;

        logNewRelicPageAction('MV:PaymentGrid - Change term value', {
            mvOfferType: offerType,
            mvFieldEdited: 'mv:payment:grid:term',
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updatePaymentGridTerm(offerType, item.key, value));
    };

    return (
        <GridItem xs={3}>
            <NumericInput
                sx={{ width: '100%' }}
                data-testid={`term-month-input-${term.name}-${term.val}`}
                name={term.name}
                label="Term (mo)"
                value={termValue}
                allowDecimal={false}
                autoInsertCommas={true}
                minValue={1}
                onChange={handleChange}
                onBlur={handleBlur(term)}
            />
        </GridItem>
    );
};
