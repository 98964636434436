import { memo, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Button } from '@interstate/components/Button';
import { ArrowUturnLeftIcon, TrashIcon } from '@interstate/components/Icons';
import { Tooltip } from '@interstate/components/Tooltip';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { ManualIncentiveEditEntry, transformIncentiveAmount } from '@makemydeal/dr-dash-store';

import { RebateDeleteOrUndoContainer, RowGrid } from './RebatesEdit.interstate.styles';
import { RebatesTableInput } from './RebatesTableInput.interstate';
import { getIncentiveDisplayType } from './utils';

export interface RebatesTableRowProps {
    incentive: ManualIncentiveEditEntry;
    term: number | undefined;
    codeCellErrorIndexes: Set<number>;
    incentiveIndex: number;
    haveIncentivesUpdated: boolean;
    isUniqueCode: boolean;
    isEditing: boolean;
    handleIncentiveDeleteOrUndo: (index: number) => void;
    updateHasMessageErrors: (value: boolean) => void;
    handleInputChange: (
        textValue: string | number | undefined,
        fieldName: keyof ManualIncentiveEditEntry,
        incentiveIndex: number
    ) => void;
}

export const RebatesTableRow = memo(function RebatesTableRow({
    incentive,
    term,
    codeCellErrorIndexes,
    incentiveIndex,
    haveIncentivesUpdated,
    isUniqueCode,
    isEditing,
    handleIncentiveDeleteOrUndo,
    updateHasMessageErrors,
    handleInputChange
}: RebatesTableRowProps) {
    const isWithinSmallViewport = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });

    const columnMappings = useMemo(
        () =>
            ({
                'Program #': {
                    fieldName: 'program',
                    fieldValue: incentive.program,
                    required: false
                },
                'Incentive Name': {
                    fieldName: 'name',
                    fieldValue: incentive.name,
                    required: true
                },
                Code: {
                    fieldName: 'code',
                    fieldValue: incentive.code,
                    required: false
                },
                Type: {
                    fieldName: 'type',
                    fieldValue: incentive.type,
                    required: true
                },
                Amount: {
                    fieldName: 'amount',
                    fieldValue: transformIncentiveAmount(term, incentive),
                    required: true
                }
            } as const),
        [incentive, term]
    );
    const displayType = useMemo(() => getIncentiveDisplayType(incentive), [incentive]);

    const [hasDuplicateCode, setHasDuplicateCode] = useState(false);

    useEffect(() => {
        if (!incentive.deleted && (isEditing || codeCellErrorIndexes.has(incentiveIndex))) {
            if (!isUniqueCode) {
                codeCellErrorIndexes.add(incentiveIndex);
                updateHasMessageErrors(true);
                setHasDuplicateCode(true);
            } else {
                codeCellErrorIndexes.delete(incentiveIndex);
                setHasDuplicateCode(false);
            }
        }
    }, [incentive, codeCellErrorIndexes, isEditing, isUniqueCode, incentiveIndex, updateHasMessageErrors]);

    // This is the only way we can identify incentives that came from program-incentives
    const isFromProgramIncentives = !!incentive.description && incentive.description !== incentive.name;

    const deleteRevertButton = useMemo(() => {
        return incentive.deleted ? (
            <>
                {(isWithinSmallViewport || incentiveIndex === 0) && <label data-testid={`undo-${incentiveIndex}`}>&nbsp;</label>}
                <Button
                    buttonStyle="secondary"
                    icon={
                        <Tooltip
                            position="bottom"
                            toolTipContent="Item was deleted. You can still undo this before updating the Offer."
                            size="large"
                        >
                            <ArrowUturnLeftIcon color="#005BA8" />
                        </Tooltip>
                    }
                    size="medium"
                />
            </>
        ) : (
            <>
                {(isWithinSmallViewport || incentiveIndex === 0) && <label data-testid={`delete-${incentiveIndex}`}>&nbsp;</label>}
                <Button
                    buttonStyle="secondary"
                    icon={<TrashIcon data-testid={`manual-rebate-delete-${incentiveIndex}`} color="#005BA8" />}
                    size="medium"
                />
            </>
        );
    }, [incentive.deleted, incentiveIndex, isWithinSmallViewport]);

    return (
        <>
            {incentiveIndex > 0 && <hr />}
            <RowGrid data-testid={`incentive-row-${incentiveIndex}`}>
                <RebateDeleteOrUndoContainer
                    data-testid={`manual-rebate-trash-icon-${incentiveIndex}`}
                    onClick={() => handleIncentiveDeleteOrUndo(incentiveIndex)}
                >
                    {deleteRevertButton}
                </RebateDeleteOrUndoContainer>

                {Object.entries(columnMappings).map(([incentiveLabel, inputProps], columnIndex) => (
                    <RebatesTableInput
                        key={`${incentiveIndex}-${columnIndex}`}
                        hasCodeDuplicate={hasDuplicateCode}
                        deleted={incentive.deleted}
                        manual={incentive.manual}
                        type={incentive.type}
                        isFromProgramIncentives={isFromProgramIncentives}
                        displayType={displayType}
                        incentiveLabel={incentiveLabel}
                        fieldValue={inputProps.fieldValue}
                        fieldName={inputProps.fieldName}
                        required={inputProps.required}
                        haveIncentivesUpdated={haveIncentivesUpdated}
                        incentiveIndex={incentiveIndex}
                        handleInputChange={handleInputChange}
                    />
                ))}
            </RowGrid>
        </>
    );
});
