import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

import useEditableProp from './useEditableProp';
import { CRT_TAX, MUT_TAX } from '../../constants';

import { ETaxNames, CategorizedTaxes } from '../../types';

export const excludeUnamedTaxes = (taxes: paymentServicesTypes.TaxItems) => taxes.filter((tax) => tax.taxName);
export const hasTaxBreakdown = (taxItem: paymentServicesTypes.TaxItem): boolean => !!taxItem.taxBreakdown;

export const categorizeTaxes = (taxItems: paymentServicesTypes.TaxItems, canOverride: boolean): CategorizedTaxes => {
    const categorizedTaxes = {} as CategorizedTaxes;
    const taxTypes = taxItems.map((item) => {
        return {
            item,
            type: item.taxName || item.class
        };
    });

    taxItems.forEach((item) => {
        let category = ETaxNames.ADDITIONAL;

        switch (item.taxName) {
            case CRT_TAX:
                category = ETaxNames.UPFRONT;
                break;

            case MUT_TAX:
                category = ETaxNames.MONTHLYUSE;
                break;

            default:
                category = canOverride ? ETaxNames.UPFRONT : ETaxNames.CAPITALIZED;
        }

        if (categorizedTaxes[category] == null) {
            categorizedTaxes[category] = [];
        }

        categorizedTaxes[category].push(item);
    });

    return categorizedTaxes;
};

export const getTaxOvverrideDisabled = (monthlyUseTaxTotal: number, capCostReductionTaxTotal: number): boolean => {
    return Boolean(monthlyUseTaxTotal || capCostReductionTaxTotal);
};

export { useEditableProp };
