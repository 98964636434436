// externals
import { useSelector } from 'react-redux';

// libraries
import { Card } from '@interstate/components/Card';
import { dealHistorySelectors, offerSelectors, quickCompareSelectors } from '@makemydeal/dr-dash-store';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { CASH, FINANCE, IOffer, IShopper, LEASE, OfferType } from '@makemydeal/dr-platform-types';

// components
import { MakeCurrentModal } from '../makeCurrentModal/MakeCurrentModal.interstate';
import OfferCardHeader from '../offerCardHeader/OfferCardHeader.interstate';
import { OfferMessage } from '../offerMessage/OfferMessage.interstate';
import CompareColumnSummary from './CompareColumnSummary.interstate';
import { StyledOfferCompareColumn } from './OfferCompareColumn.interstate.style';
import { CompareCashColumnDetail } from './compareColumnDetails/CompareCashColumnDetail.interstate';
import { CompareFinanceColumnDetail } from './compareColumnDetails/CompareFinanceColumnDetail.interstate';
import { CompareLeaseColumnDetail } from './compareColumnDetails/CompareLeaseColumnDetail.interstate';

// interfaces/types
import type { IOfferCompareColumnProps } from './types/offerCompareColumnProps';

// utils
import { dateUtils } from '@makemydeal/dr-common-utils';
import { MakeCurrentButtonView } from '../makeCurrentModal/types';
import { determineDeltas } from '../utils/determineDeltasUtil';

const OfferCompareColumn = (props: IOfferCompareColumnProps) => {
    const {
        isCurrent,
        isDealHistory,
        onMakeCurrentOffer,
        isSelected,
        onDealClick,
        isDefaultOffer,
        isMostRecentDealerOffer,
        isMostRecentShopperOffer,
        deal
    } = props;

    const workingOffer = useSelector(offerSelectors.getWorkingOffer);
    const comparingOffer = useSelector(quickCompareSelectors.getQuickCompareOffer);
    const selectedOffers = useSelector(dealHistorySelectors.getSelectedDealHistory);
    const deltaList = determineDeltas(deal, workingOffer, isCurrent);

    const {
        shopper,
        createdOn,
        offerType,
        terms,
        penciledInputs,
        offerSource,
        applicationName,
        cmdMessageToShopper: dealerMessage,
        messageToDealer: shopperMessage
    } = deal.offer;
    const { firstName, lastName } = shopper as IShopper;

    const offerPrice = offerSelectors.getSellingPrice(deal as unknown as StateTree);
    const payment =
        offerType === CASH
            ? penciledInputs && penciledInputs.byOfferType && penciledInputs.byOfferType[CASH]
            : terms.byId[(penciledInputs as any).byOfferType[offerType as string].term]; // TODO: replace with selector US847966

    const onColumnClick = () => isDealHistory && onDealClick && onDealClick();

    const offerTypesDiffer = (offersList: IOffer[]) => {
        return offersList.some((currOffer) => currOffer.offerType !== workingOffer.offer.offerType);
    };

    const hasDifference = () => {
        if (isDealHistory) {
            return offerTypesDiffer([...selectedOffers.map((deal) => deal.offer)]);
        } else {
            return offerTypesDiffer([comparingOffer.offer]);
        }
    };

    const renderColumnDetail = () => {
        const _hasDifference = hasDifference();
        switch (offerType as OfferType) {
            case CASH:
                return <CompareCashColumnDetail deal={deal} hasDifference={_hasDifference} deltaList={deltaList} />;
            case FINANCE:
                return <CompareFinanceColumnDetail deal={deal} hasDifference={_hasDifference} deltaList={deltaList} />;
            case LEASE:
                return <CompareLeaseColumnDetail deal={deal} hasDifference={_hasDifference} deltaList={deltaList} />;
        }
    };

    const createdOnFormattedDate = createdOn && dateUtils.formatAsMMDDYYYY(new Date(createdOn));
    const createdOnFormattedTime = createdOn && dateUtils.formatAsTime(new Date(createdOn));

    return (
        <div onClick={onColumnClick} style={{ alignSelf: 'stretch' }} className="offer-compare-column-container">
            <Card
                className="offer-compare-column"
                data-testid={`${isCurrent ? 'current' : 'selected'}-offer-compare-column`}
                sx={{
                    border: isSelected ? '2px solid #005BA8' : '1px solid #B2B2B2'
                }}
                content={
                    <StyledOfferCompareColumn container gap={1} flexDirection="column">
                        <OfferCardHeader
                            createdOnDate={createdOnFormattedDate}
                            createdOnTime={createdOnFormattedTime}
                            offerSource={offerSource}
                            applicationName={applicationName}
                            isDefaultOffer={isDefaultOffer}
                            isMostRecentDealerOffer={isMostRecentDealerOffer}
                            isMostRecentShopperOffer={isMostRecentShopperOffer}
                            showSubHeader={false}
                        />
                        <CompareColumnSummary
                            isCurrentOffer={isCurrent}
                            createdOn={createdOn as string}
                            offerType={offerType as OfferType}
                            lastName={lastName}
                            firstName={firstName}
                            offerSource={offerSource}
                            offerPrice={offerPrice}
                            payment={payment}
                            isDealHistory={isDealHistory}
                            isDefaultOffer={isDefaultOffer}
                            isMostRecentDealerOffer={isMostRecentDealerOffer}
                            isMostRecentShopperOffer={isMostRecentShopperOffer}
                        />
                        {offerType && renderColumnDetail()}
                        {(shopperMessage || dealerMessage) && (
                            <OfferMessage shopperMessage={shopperMessage} dealerMessage={dealerMessage} offerSource={offerSource} />
                        )}
                        {!isCurrent && onMakeCurrentOffer && (
                            <MakeCurrentModal
                                onMakeCurrentOffer={onMakeCurrentOffer}
                                makeCurrentButtonView={
                                    isDealHistory ? MakeCurrentButtonView.DealHistory : MakeCurrentButtonView.QuickCompare
                                }
                            />
                        )}
                    </StyledOfferCompareColumn>
                }
            />
        </div>
    );
};

export default OfferCompareColumn;
