import { useDispatch, useSelector } from 'react-redux';

// selectors
import { tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';

// constants
import { TRANSMISSION } from '../../../constants';

// components
import { TextInput } from '@interstate/components/TextInput';
import { Typography } from '@interstate/components/Typography';

// styling
import { DetailContainer } from '../trade.style';

interface TransmissionFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const TransmissionField: React.FC<TransmissionFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const transmission = useSelector(tradeInSelectors.getTransmission);

    const onChange = (event: any) => {
        dispatch(tradeInActionCreators.updateTradeInTransmission(event.target.value));
    };

    return (
        <>
            {isManualFormOpen ? (
                <TextInput
                    label={TRANSMISSION}
                    data-testid={kebabCase(TRANSMISSION)}
                    name={kebabCase(TRANSMISSION)}
                    onChange={onChange}
                    value={transmission}
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {TRANSMISSION}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-transmission-value-label">
                                {transmission}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};
