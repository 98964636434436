// externals
import { useSelector } from 'react-redux';

// store
import { ScopedStateProvider, scopedStateSelectors } from '@makemydeal/dr-shared-store';

// components
import { DeskingActivityDealMultiple } from './DeskingActivityDealMultiple';

export const DeskingActivityDealMultipleWrapper = () => {
    const scopesOrder = useSelector(scopedStateSelectors.getScopesOrder);
    const primaryScopeId = useSelector(scopedStateSelectors.getPrimaryScopeId);

    return (
        <ScopedStateProvider primary>
            <DeskingActivityDealMultiple scopesOrder={scopesOrder} primaryScopeId={primaryScopeId} />
        </ScopedStateProvider>
    );
};
