// externals
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Action } from '@interstate/components/Action';
import { Alert } from '@interstate/components/Alert';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import {
    creditDecisionSelectors,
    dealXgDetailsSelectors,
    navigationActionCreators,
    offerReduxSelectors
} from '@makemydeal/dr-dash-store';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { LEASE } from '@makemydeal/dr-platform-types';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';

// components
import AcquisitionFeeAmountField from './common/AcquisitionFeeAmountField.interstate';
import AcquisitionFeeUpFrontField from './common/AcquisitionFeeUpFrontField.interstate';
import AdjustedResidualField from './common/AdjustedResidualField.interstate';
import BaseResidualField from './common/BaseResidualField.interstate';
import BuyRateField from './common/BuyRateField.interstate';
import CashDownField from './common/CashDownField.interstate';
import CreditDecisionEnabled from './common/CreditDecisionEnabled.interstate';
import CustomMileageField from './common/CustomMileageField.interstate';
import CreditDecisionLenderNameField from './common/CreditDecisionLenderNameField.interstate';
import CreditDecisionStatusField from './common/CreditDecisionStatusField.interstate';
import ExcessMileageChargeField from './common/ExcessMileageChargeField.interstate';
import LeaseTermField from './common/LeaseTermField.interstate';
import MaxResidualizedMsrpAppliedROField from './common/MaxResidualizedMsrpAppliedROField.interstate';
import MaxResidualizedMsrpROField from './common/MaxResidualizedMsrpROField.interstate';
import MilesPerYearField from './common/MilesPerYearField.interstate';
import PaymentFrequencyField from './common/PaymentFrequencyField.interstate';
import RetailPriceField from './common/RetailPriceField.interstate';
import SecurityDepositField from './common/SecurityDepositField.interstate';
import SellRateField from './common/SellRateField.interstate';
import SellingPriceField from './common/SellingPriceField.interstate';
import { TermsTab } from './common/Terms.style';

// styles
import { LeaseHeader } from './Lease.styles';
import PaymentGridToggle from './common/PaymentGridToggle';
import { ContainerGrid } from '../common/Layout.style';
import { ContainerGridItem } from '../common/ContainerGridItem';

export type LeaseProps = {
    enableCreditDecisionAlert?: boolean;
};

const Lease = ({ enableCreditDecisionAlert = false }: LeaseProps) => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const enableLenderFeeEdits = useSelector(featureToggleSelectors.enableLenderFeeEdits);
    const lenderDecisions = useSelector((state: StateTree) => {
        return creditDecisionSelectors.getLenderCreditDecisionsByOfferType(state, currentOfferType);
    });

    const enableCreditBureauAndDecisionPBCs = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);
    const enableCreditBureauAndDecisionPBCRouteOne = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCRouteOne);

    const isCreditProviderRouteOne: boolean = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    const dispatch = useDispatch();

    const creditDecisionAvailable = lenderDecisions.length > 0;

    const navigateToCreditBureau = () => dispatch(navigationActionCreators.navigateToCreditBureau());
    const navigateToCreditDecision = () => dispatch(navigationActionCreators.navigateToCreditDecision());
    const enableCreditDecisionForR1 =
        enableCreditBureauAndDecisionPBCs && enableCreditBureauAndDecisionPBCRouteOne && isCreditProviderRouteOne;
    const enableCreditDecisionForDealerTrack = enableCreditBureauAndDecisionPBCs && !isCreditProviderRouteOne;
    const shouldNavigateToCreditDecision = enableCreditDecisionForDealerTrack || enableCreditDecisionForR1;

    const paymentGridEnabled: boolean = useSelector((state: StateTree) =>
        offerReduxSelectors.getPaymentGridToggle(state, currentOfferType)
    );

    const showAllLeaseFields = usePaymentGridMV ? !paymentGridEnabled : true;

    const enableEcommerceDealWithILDP = useSelector(featureToggleSelectors.enableEcommerceDealWithILDP);
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const orderDetailsLink = useSelector(dealXgDetailsSelectors.getOrderDetailsLink);
    const isEnhancedLeaseDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    const handleViewLenderDecisionClick = () => {
        if (enableEcommerceDealWithILDP && isEcommerceDeal && currentOfferType === LEASE) {
            window.open(`${orderDetailsLink}?show=ildp`, '_blank');
        } else if (shouldNavigateToCreditDecision) {
            navigateToCreditDecision();
        } else {
            navigateToCreditBureau();
        }
    };

    if (currentOfferType !== LEASE) return null;

    const leaseFields = [
        { component: <CashDownField />, key: 'CashDownField' },
        { component: <CreditDecisionLenderNameField />, key: 'CreditDecisionLenderNameField' },
        { component: <CreditDecisionStatusField />, key: 'CreditDecisionStatusField' },
        { component: <LeaseTermField />, key: 'LeaseTermField' },
        { component: <PaymentFrequencyField />, key: 'PaymentFrequencyField' },
        { component: <SellRateField />, key: 'SellRateField' },
        { component: <BuyRateField />, key: 'BuyRateField' },
        { component: <AcquisitionFeeAmountField />, key: 'AcquisitionFeeAmountField' },
        { component: <BaseResidualField />, key: 'BaseResidualField' },
        { component: <AdjustedResidualField />, key: 'AdjustedResidualField' },
        isEnhancedLeaseDetailsEnabled
            ? {
                  component: <CustomMileageField />,
                  key: 'CustomMileageField'
              }
            : {
                  component: <MilesPerYearField />,
                  key: 'MilesPerYearField'
              },
        {
            component: (
                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                    <MaxResidualizedMsrpROField />
                    <MaxResidualizedMsrpAppliedROField />
                </div>
            ),
            key: 'MaxResidualizedMsrpROField-MaxResidualizedMsrpAppliedROField'
        },
        { component: <SecurityDepositField />, key: 'SecurityDepositField' },
        { component: <ExcessMileageChargeField />, key: 'ExcessMileageChargeField' }
    ];

    const isPaymentGridEnabled = usePaymentGridMV && paymentGridEnabled;
    return (
        <TermsTab data-testid="lease-terms">
            <LeaseHeader>
                <Typography data-testid="lease-title" variant="h5">
                    Payment And Lender Information
                </Typography>
                <Action size="sm" data-testid="lease-terms-edit-link" onClick={handleViewLenderDecisionClick}>
                    View Lender Decisions
                </Action>
            </LeaseHeader>
            <Grid xs={12}>
                {enableCreditDecisionAlert && creditDecisionAvailable && (
                    <Alert id="infoAlert" role="infoAlert" type="info" data-testid="terms-lender-decision-alert">
                        Lender decisions are ready for this offer.
                    </Alert>
                )}
            </Grid>
            {!isABPencilEnabled && (
                <Grid xs={12}>
                    <CreditDecisionEnabled />
                </Grid>
            )}
            {usePaymentGridMV && (
                <Grid xs={12}>
                    <PaymentGridToggle currentOfferType={currentOfferType} />
                </Grid>
            )}
            <ContainerGrid data-testid="main-lease-content">
                <ContainerGridItem xs={6} md={4}>
                    <RetailPriceField />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <SellingPriceField />
                </ContainerGridItem>
                {showAllLeaseFields && (
                    <>
                        {leaseFields.map(({ key, component }) => (
                            <ContainerGridItem key={key} xs={6} md={4}>
                                {component}
                            </ContainerGridItem>
                        ))}
                    </>
                )}
                {isPaymentGridEnabled && (
                    <>
                        <ContainerGridItem xs={6} md={4}>
                            <MilesPerYearField />
                        </ContainerGridItem>
                        <ContainerGridItem xs={6} md={4}>
                            <SecurityDepositField />
                        </ContainerGridItem>
                        <ContainerGridItem xs={6} md={4}>
                            <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
                                <AcquisitionFeeAmountField />
                                <AcquisitionFeeUpFrontField />
                            </div>
                        </ContainerGridItem>
                        <ContainerGridItem xs={6} md={4}>
                            &nbsp;
                        </ContainerGridItem>
                    </>
                )}
            </ContainerGrid>
        </TermsTab>
    );
};

export default Lease;
