//istanbul ignore file #TODO: DE388248
// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { SlideOut } from '@interstate/components/SlideOut';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';

// libraries
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { bootstrapSharedActionCreators } from '@makemydeal/dr-shared-store';
import { dealHistoryActionTypes, offerSelectors, quickCompareSelectors } from '@makemydeal/dr-dash-store';

// actions
import { quickCompareActionCreators } from '@makemydeal/dr-dash-store';
import OfferCompareColumn from '../offerCompareColumn/OfferCompareColumn.interstate';
import { StyledColumnsContainer } from './QuickCompare.interstate.style';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

export type QuickCompareProps = {
    onCompareClick: () => void;
};

const QuickCompare: React.FC<QuickCompareProps> = ({ onCompareClick }: QuickCompareProps) => {
    const dispatch = useDispatch();
    const workingOffer = useSelector(offerSelectors.getWorkingOffer) || ({} as DealHistory);
    const comparingOffer = useSelector(quickCompareSelectors.getQuickCompareOffer) || ({} as DealHistory);
    const dealXgVersion = comparingOffer && comparingOffer.offer ? comparingOffer.offer.dealXgVersion : undefined;
    const [showModal, setShowModal] = React.useState(false);

    const cleanQuickCompareOffer = React.useCallback(() => {
        dispatch(quickCompareActionCreators.updateQuickCompareOffer({} as DealHistory));
    }, [dispatch]);

    const dispatchMakeCurrentOffer = React.useCallback(() => {
        dispatch({ type: dealHistoryActionTypes.FETCH_DEAL_HISTORY_REQUEST });
        dispatch(
            bootstrapSharedActionCreators.fetchBootstrapData(MANAGER_VIEW_APP_PREFIX, {
                withCurrentDealVersion: true,
                dealXgVersion
            }) as any
        );
    }, [dispatch, comparingOffer, dealXgVersion]);

    const toggleModal = () => {
        onCompareClick();
        setShowModal(!showModal);
    };

    const onModalClose = () => {
        cleanQuickCompareOffer();
        toggleModal();
    };

    const _makeCurrentOffer = () => {
        setShowModal(!showModal);
        cleanQuickCompareOffer();
        dispatchMakeCurrentOffer();
    };

    return (
        <>
            <Action id="btnQuickCompare" onClick={toggleModal} size="sm" data-testid="int-quick-compare-button">
                <Typography variant="anchor-block-md" color="sem.color.surface.button.primary.rest">
                    Quick Compare
                </Typography>
            </Action>
            <SlideOut
                data-testid="quick-compare-drawer"
                show={showModal}
                position="right"
                panelWidth="auto"
                onHide={onModalClose}
                header="Quick Comparison"
                sx={{
                    '.ids-SlideOut-content': {
                        padding: '0 30px 30px 36px'
                    }
                }}
            >
                <StyledColumnsContainer container gap={2}>
                    <OfferCompareColumn deal={workingOffer} isCurrent={true} />

                    <OfferCompareColumn
                        deal={comparingOffer}
                        onMakeCurrentOffer={_makeCurrentOffer}
                        isMostRecentDealerOffer={true}
                        isMostRecentShopperOffer={false}
                    />
                </StyledColumnsContainer>
            </SlideOut>
        </>
    );
};

export default QuickCompare;
