import { RetailPriceFieldProps } from '../types';
import { EditedTaxFieldInputContainer } from './EditedTaxField.style';
import { NumericInput } from '@interstate/components/NumericInput';
import { useEditableProp } from './helpers';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

export default function TaxItemAmountField<Props extends RetailPriceFieldProps>({ data, handleBlur }: Props) {
    const [amount, setAmount, isEdited] = useEditableProp(data.amount, data.isEditedAmount);

    const handleBlurWrapper = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (!amount && !data.amount) {
            setAmount('0');
            return;
        }
        handleBlur(e, data.amountKey);
    };

    return (
        <EditedTaxFieldInputContainer $isEdited={isEdited}>
            <NumericInput
                data-testid={`detailed-table-cell-amount-input-${data.name}`}
                name={'detail-amount'}
                inputPrefix={'$'}
                sx={{ width: '105px' }}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                onBlur={handleBlurWrapper}
                allowDecimal={true}
                decimalMaxLength={2}
                maxValue={999999.99}
                placeholder={'0'}
                autoInsertCommas={true}
            />
        </EditedTaxFieldInputContainer>
    );
}
