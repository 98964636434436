import {
    SCOPES_CHANGE_DEFAULT_SCOPE,
    SCOPES_CLONE_SCOPE,
    SCOPES_SET_NAVIGATION_SCOPE_TARGET,
    SCOPES_DELETE_SCOPE
} from '../actionTypes/scopedActionTypes';

export const setNavigationScopeTarget = () => ({
    type: SCOPES_SET_NAVIGATION_SCOPE_TARGET
});

export const changeDefaultScope = (scopeId: string) => ({
    type: SCOPES_CHANGE_DEFAULT_SCOPE,
    payload: {
        newDefaultScope: scopeId
    }
});

export const cloneScope = (scopeToClone: string, metadata: any = {}) => ({
    type: SCOPES_CLONE_SCOPE,
    payload: {
        scopeToClone,
        metadata
    }
});

export const deleteScope = (scopeToDelete: string, metadata: any = {}) => ({
    type: SCOPES_DELETE_SCOPE,
    payload: {
        scopeToDelete,
        metadata
    }
});
