import React from 'react';

export const ContainerGridItem = ({
    children,
    xs,
    md,
    lg
}: {
    children: React.ReactNode;
    xs?: number;
    md?: number;
    lg?: number;
}) => {
    const classes = [
        xs ? `container-grid-item-xs-${xs}` : null,
        md ? `container-grid-item-md-${md}` : null,
        lg ? `container-grid-item-lg-${lg}` : null
    ]
        .filter(Boolean)
        .join(' ');
    return <div className={classes}>{children}</div>;
};
