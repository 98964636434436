import React, { useMemo } from 'react';

import { CheckBox } from '@interstate/components/CheckBox';
import { ACQUISITION_FEE_ABBR, GENERIC_LAST_FIELD_CHANGED_ERROR, ACQUISITION_FEE_OPTION_LABEL_INTERSTATE } from '../../constants';
import {
    deskingSelectors,
    offerActionCreators,
    offerReduxSelectors,
    offerSelectors,
    offerActionTypes,
    ACQUISITION_FEE_ID
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { usePaymentCall } from '../../utils/usePaymentCall';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { useSelector, useDispatch } from 'react-redux';
import { NumericInput } from '@interstate/components/NumericInput';
import { RSAAAction } from 'redux-api-middleware';
import { IDealerFee } from '@makemydeal/dr-platform-types';

const AcquisitionFeeAmountField = () => {
    const { updateAcqFeeOverride } = offerActionCreators;
    const dispatch = useDispatch();

    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_ACQFEE_OVERRIDE));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    const checked = useSelector(offerSelectors.getAcqFeeUpFrontOverrideWithFallback);

    const { value, handleChange, handleBlur } = usePaymentCall(offerSelectors.getAcqFeeOverrideWithFallback, updateAcqFeeOverride, {
        formatValueToString: (dollars: any) => formatDollarsAndCents(dollars)
    });

    // istanbul ignore next
    const onChange = (e: any) => {
        dispatch(
            offerActionCreators.updateAcqFeeUpFrontOverride(
                e.target.checked === undefined ? (e as any).domEvent.target.checked : e.target.checked
            ) as unknown as RSAAAction<any, unknown, unknown>
        );
    };

    return (
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
            <NumericInput
                id={kebabCase(ACQUISITION_FEE_ABBR)}
                hasError={failed}
                errorMessage={failedMessage}
                label={ACQUISITION_FEE_ABBR}
                name={kebabCase(ACQUISITION_FEE_ABBR)}
                placeholder="$0"
                value={value}
                data-testid={kebabCase(ACQUISITION_FEE_ABBR)}
                onChange={handleChange}
                onBlur={handleBlur}
                allowDecimal={true}
                decimalMaxLength={2}
                decimalMinLength={2}
                inputPrefix={'$'}
                autoInsertCommas={true}
            />
            <CheckBox
                data-testid={kebabCase(ACQUISITION_FEE_ABBR)}
                id={kebabCase(ACQUISITION_FEE_ABBR)}
                label={ACQUISITION_FEE_OPTION_LABEL_INTERSTATE}
                name={kebabCase(ACQUISITION_FEE_ABBR)}
                onChange={onChange}
                checked={checked}
                value="1"
            />
        </div>
    );
};

export default AcquisitionFeeAmountField;
