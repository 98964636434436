// libraries
import type { DealState } from '@makemydeal/dr-dash-types';
import { tradeInCardSelectors, tradeInSelectors } from '@makemydeal/dr-dash-store';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import { Grid } from '@interstate/components/Grid';

import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

export type NetTradeProps = DealState & {
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const NetTrade = ({ deal, useWrapper = true, hasDelta = false }: NetTradeProps) => {
    const useDealRootSelector = createDealRootSelector(deal);

    const shouldIncludeTradeIn = useDealRootSelector(tradeInCardSelectors.getShouldIncludeTradeIn);
    const isTradeInCompleted = useDealRootSelector(tradeInSelectors.isTradeInCompleted);
    const netTradeValue = useDealRootSelector(tradeInSelectors.getTradeNetValue);
    const tradeValue = useDealRootSelector(tradeInSelectors.getTradeValue);
    const amountOwed = useDealRootSelector(tradeInSelectors.getTradeInAmountOwed);
    const displayTradeLineItems = shouldIncludeTradeIn && isTradeInCompleted;

    if (!displayTradeLineItems && !deal) {
        return <DealSummaryItem label={constants.NET_TRADE} value={formatDollarsAndCents(0)} />;
    }

    return (
        // TODO: change to DealSummaryItem and remove useWrapper prop, and refactor IOfferCompareDetailSection to accept DealSummaryItem
        <DealSummaryItemWrapper
            label={constants.NET_TRADE}
            value={formatDollarsAndCents(netTradeValue)}
            useWrapper={useWrapper}
            hasDelta={hasDelta}
        >
            <Grid>
                <LineItemWithChildren label={constants.TRADE_ALLOWANCE} value={formatDollarsAndCents(tradeValue)} />
                <LineItemWithChildren label={constants.TRADE_OFF} value={formatDollarsAndCents(amountOwed)} />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default NetTrade;
