/**
 * PURPOSE: Provide reducers that are not required for the initial boostrap of the application.  For the bootstrap related reducers
 *   take a look at `initialReducers.ts`.
 */

// externals
import { AnyAction, Reducer } from 'redux';

// libraries
import {
    rootReducer as offerReducer,
    configureReducer as configureOfferReducer,
    paymentReducer,
    paymentTypes
} from '@makemydeal/dr-offer-redux';
import { IOffer, OfferType } from '@makemydeal/dr-platform-types';
import { InitContext } from '@makemydeal/dr-shared-store';
import {
    dealLifecycleReducer,
    paymentFeesTypesReducer,
    vAutoReducer,
    vAutoTriforceReducer,
    tradeInCardReducer,
    deskingGlobalReducer
} from '@makemydeal/dr-dash-store';

// reducers
import {
    accessoriesReducer,
    activityMilestoneReducer,
    bridgeUserReducer,
    calculationDetailsReducer,
    connectionReducer,
    consumerInsightsReducer,
    creditAppReducer,
    creditDecisionReducer,
    customerUIReducer,
    dashBootstrapReducer,
    dealerReducer,
    dealHistoryReducer,
    dealXgDetailsReducer,
    deskingReducer,
    docUploadReducer,
    internalPrevOfferReducer,
    leadReducer,
    lenderListReducer,
    offerInfoReducer,
    programsReducer,
    pushToDmsReducer,
    pushToDarwinReducer,
    pushToRouteOneReducer,
    printPdfReducer,
    quickCompareReducer,
    rollbackReducer,
    rollbackTradeInReducer,
    shopperNotificationsReducer,
    testDriveReducer,
    tradeInReducer,
    vehicleProtectionReducer,
    vehicleReducer
} from '@makemydeal/dr-dash-store';

// config
import { changeVehicleConfiguration } from '../activities/change-vehicle';
import { TradeReducer, tradeConfiguration } from '../activities/trade';

// utils
import { shouldRevertPaymentOnFailure } from './utils/offerPaymentReverter';

// types
import { ChangeVehicleReducer } from '@makemydeal/dr-activities-change-vehicle';

export type OfferReducer = Reducer<IOffer>;

export type PaymentReducer = Reducer<any>;

export type RemainingReducers = {
    accessories: accessoriesReducer.AccessoriesReducer;
    activityMilestones: activityMilestoneReducer.ActivityMilestoneReducer;
    bootstrap: dashBootstrapReducer.BootstrapReducer;
    bridgeUser: bridgeUserReducer.BridgeUserReducer;
    calculationDetails: calculationDetailsReducer.CalculationDetailsReducer;
    changeVehicle: ChangeVehicleReducer;
    connection: connectionReducer.ConnectionReducer;
    consumerInsights: consumerInsightsReducer.ConsumerInsightsReducer;
    creditApp: creditAppReducer.CreditAppReducer;
    creditDecision: creditDecisionReducer.CreditDecisionReducer;
    customerUI: customerUIReducer.CustomerUIReducer;
    dealer: dealerReducer.DealerReducer;
    dealHistory: dealHistoryReducer.DealHistoryReducer;
    dealLifecycle: dealLifecycleReducer.DealLifecycleReducer;
    dealXgDetails: dealXgDetailsReducer.DealXgDetailsReducer;
    desking: deskingReducer.DeskingReducer;
    deskingGlobal: deskingGlobalReducer.DeskingGlobalReducer;
    docUpload: docUploadReducer.DocUploadReducer;
    internalPrevOffer: internalPrevOfferReducer.InternalPrevOfferReducer;
    lead: leadReducer.LeadReducer;
    lenderList: lenderListReducer.LenderListReducer;
    offer: OfferReducer;
    offerInfo?: offerInfoReducer.OfferInfoReducer;
    payment: PaymentReducer;
    programs?: programsReducer.ProgramsReducer;
    pushToDms: pushToDmsReducer.PushToDmsReducer;
    pushToDarwin: pushToDarwinReducer.PushToDarwinReducer;
    pushToRouteOne: pushToRouteOneReducer.PushToRouteOneReducer;
    printPdf: printPdfReducer.PrintPdfReducer;
    quickCompare: quickCompareReducer.QuickCompareReducer;
    rollback: rollbackReducer.RollbackReducer;
    rollbackTradeIn: rollbackTradeInReducer.RollbackTradeInReducer;
    shopperNotifications: shopperNotificationsReducer.ShopperNotificationsReducer;
    testDrive: testDriveReducer.TestDriveReducer;
    tradeIn: tradeInReducer.TradeInReducer;
    tradeInCard: tradeInCardReducer.TradeInCardReducer;
    tradeInComponent?: TradeReducer;
    vAuto?: vAutoReducer.vAutoReducer;
    vAutoTriforce?: vAutoTriforceReducer.vAutoTriforceReducer;
    vehicle: vehicleReducer.VehicleReducer;
    vehicleProtectionV2?: vehicleProtectionReducer.VehicleProtectionReducer;
};

export const paymentOfferAndDashReducers = (state = {}, action: AnyAction) => {
    const paymentState = paymentReducer(state as paymentTypes.PaymentState, action);
    const internalAndOfferPaymentState = paymentFeesTypesReducer.reducer(paymentState, action);

    return internalAndOfferPaymentState;
};

const configureOfferReducerForMenuActivity = (isMenuActivityEnabled = false) => {
    const offerReducerConfig = {
        selectors: {
            shouldRevertPaymentOnFailure: (state: any, action: any, offerType: OfferType): boolean =>
                shouldRevertPaymentOnFailure(state, action, offerType)
        }
    };
    configureOfferReducer(offerReducerConfig);
};

/**
 * Retrieve global reducers that aren't needed for the initial bootstrap of the application.  These reducers can be conditionally included
 * based on feature toggles etc.
 * Global Reducers represent data that is shared between all deals that could be open in parallel
 * Example: Bridge User and Dealer, they will be the same, no matter which deal is opened
 * @param context object that can provide additional context such as feature toggles
 * @returns object containing global reducers
 * @see {@link getScopedRemainingReducers}
 */
export const getGlobalRemainingReducers = (context: InitContext): Partial<RemainingReducers> => {
    const useMenuActivity = context.featureToggles.dashUseMenuActivity;
    const useTradeActivity = context.featureToggles.dashUseTradeActivity;

    configureOfferReducerForMenuActivity(useMenuActivity);

    const globalReducers: Partial<RemainingReducers> = {
        bridgeUser: bridgeUserReducer.reducer,
        bootstrap: dashBootstrapReducer.reducer,
        dealHistory: dealHistoryReducer.reducer,
        dealer: dealerReducer.reducer,
        deskingGlobal: deskingGlobalReducer.reducer,
        shopperNotifications: shopperNotificationsReducer.reducer,
        tradeIn: tradeInReducer.reducer
    };

    if (useTradeActivity) {
        globalReducers.tradeInComponent = tradeConfiguration.reducer;
    }

    return globalReducers;
};

/**
 * Retrieve reducers that aren't needed for the initial bootstrap of the application.  These reducers can be conditionally included
 * based on feature toggles etc.
 * Scoped Reducers represent data that is isolated between deals that could be open in parallel
 * Example: Accessories, Offer and Vehicle Protection, since each deal will potentially have their own values for them
 * @param context object that can provide additional context such as feature toggles
 * @returns object containing scoped reducers
 * @see {@link getGlobalRemainingReducers}
 */
export const getScopedRemainingReducers = (context: InitContext): Partial<RemainingReducers> => {
    const useMenuActivity = context.featureToggles.dashUseMenuActivity;
    const useTradeActivity = context.featureToggles.dashUseTradeActivity;

    configureOfferReducerForMenuActivity(useMenuActivity);

    const dealSpecificReducers: Partial<RemainingReducers> = {
        accessories: accessoriesReducer.reducer,
        activityMilestones: activityMilestoneReducer.reducer,
        calculationDetails: calculationDetailsReducer.reducer,
        changeVehicle: changeVehicleConfiguration.reducer,
        connection: connectionReducer.reducer,
        consumerInsights: consumerInsightsReducer.reducer,
        creditApp: creditAppReducer.reducer,
        creditDecision: creditDecisionReducer.reducer,
        customerUI: customerUIReducer.reducer,
        dealLifecycle: dealLifecycleReducer.reducer,
        dealXgDetails: dealXgDetailsReducer.reducer,
        desking: deskingReducer.reducer,
        docUpload: docUploadReducer.reducer,
        internalPrevOffer: internalPrevOfferReducer.reducer,
        lead: leadReducer.reducer,
        lenderList: lenderListReducer.reducer,
        offer: offerReducer,
        payment: paymentOfferAndDashReducers,
        programs: programsReducer.reducer,
        pushToDms: pushToDmsReducer.reducer,
        pushToDarwin: pushToDarwinReducer.reducer,
        pushToRouteOne: pushToRouteOneReducer.reducer,
        printPdf: printPdfReducer.reducer,
        quickCompare: quickCompareReducer.reducer,
        rollback: rollbackReducer.reducer,
        rollbackTradeIn: rollbackTradeInReducer.reducer,
        testDrive: testDriveReducer.reducer,
        vehicle: vehicleReducer.reducer,
        vAuto: vAutoReducer.reducer,
        vAutoTriforce: vAutoTriforceReducer.reducer,
        vehicleProtectionV2: vehicleProtectionReducer.reducer
    };

    if (useMenuActivity) {
        dealSpecificReducers.offerInfo = offerInfoReducer.reducer;
    }

    if (useTradeActivity) {
        dealSpecificReducers.tradeInComponent = tradeConfiguration.reducer;
        dealSpecificReducers.tradeInCard = tradeInCardReducer.reducer;
    }

    return dealSpecificReducers;
};
