// externals
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// selectors
import { featureToggleSelectors, scopedActionsCreators } from '@makemydeal/dr-shared-store';

// components
import { Card } from '@interstate/components/Card';
import { GridTableInterstate, Terms, CreditBureau, PaymentStatus } from '@makemydeal/dr-dash-components';
import { Action } from '@interstate/components/Action';
import { DocumentDuplicateIcon, TrashIcon } from '@interstate/components/Icons';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { StateTree } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import InterstateAccessoriesCard from '../accessories/AccessoriesCard.interstate';
import { DeskingAlerts } from '../DeskingAlerts';
import { FeesCard } from '../fees/FeesCard';
import { RebatesIndex } from '../rebates/index';
import TaxCard from '../taxes/Taxes/TaxCard';
import InterstateTradeFormCard from '../tradeForm/TradeFormCard.interstate';
import { VehicleProtectionPreview } from '../vehicleProtection/VehicleProtectionPreview';
import { VehicleInfoCard } from '../vehicleInfo/VehicleInfoCard';
import {
    DeskingCardBlock,
    StyledDeskingCard,
    DeskingCardBlocksContainer,
    DeskingCardHeader,
    DeskingCardLeftPanel,
    DeskingCardHeaderActions,
    DeskingCardHeaderActionsMenu
} from './DeskingCard.style';
import { DeskingCardDealLabel } from './DeskingCardDealLabel';
import { DeskingCardPaymentDetailsSidePanel } from './DeskingCardPaymentDetailsSidePanel';
import { DeskingCardPaymentDetailsAccordion } from './DeskingCardPaymentDetailsAccordion';

export interface DeskingCardProps {
    scopeId: string;
    isDuplicateDealAllowed: boolean;
    isSingleCardView: boolean;
    isPrimaryScope: boolean;
}

const TermsCardSx = { height: '100%' };

export const DeskingCard = memo(({ isPrimaryScope, scopeId, isDuplicateDealAllowed, isSingleCardView }: DeskingCardProps) => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);
    const paymentGridEnabledToggle = useSelector((state: StateTree) =>
        offerReduxSelectors.getPaymentGridToggle(state, currentOfferType)
    );
    const isLargeScreen = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenLg})` });

    const isPaymentGridEnabled = usePaymentGridMV && paymentGridEnabledToggle;
    const isPaymentDetailsInSidePanel = isSingleCardView && isLargeScreen;

    const handleDuplicate = useCallback(() => {
        dispatch(scopedActionsCreators.cloneScope(scopeId));
    }, [dispatch, scopeId]);

    const deleteScope = useCallback(() => {
        dispatch(scopedActionsCreators.deleteScope(scopeId));
    }, [dispatch, scopeId]);

    return (
        <StyledDeskingCard
            data-testid={`deal-content-card-${scopeId}`}
            isSingleCardView={isSingleCardView}
            isLargeScreen={isLargeScreen}
            isPrimaryScope={isPrimaryScope}
            theme={theme}
        >
            <DeskingCardHeader>
                <DeskingCardHeaderActions theme={theme}>
                    <DeskingCardDealLabel isPrimaryScope={isPrimaryScope} scopeId={scopeId}></DeskingCardDealLabel>
                    <DeskingCardHeaderActionsMenu>
                        <Action
                            actionIcon={{ icon: <TrashIcon />, start: true }}
                            id="delete-deal-draft-button"
                            data-testid="delete-deal-draft-button"
                            onClick={deleteScope}
                            size="lg"
                            disabled={isPrimaryScope}
                        />
                        <Action
                            actionIcon={{ icon: <DocumentDuplicateIcon />, start: true }}
                            id="duplicate-deal-draft-button"
                            data-testid="duplicate-deal-draft-button"
                            onClick={handleDuplicate}
                            size="lg"
                            disabled={!isDuplicateDealAllowed}
                        />
                    </DeskingCardHeaderActionsMenu>
                </DeskingCardHeaderActions>

                <VehicleInfoCard />

                <PaymentStatus sx={{ marginBottom: 0 }} />
                <DeskingAlerts />
            </DeskingCardHeader>

            {isPaymentDetailsInSidePanel && (
                <DeskingCardLeftPanel>
                    <DeskingCardPaymentDetailsSidePanel />
                </DeskingCardLeftPanel>
            )}

            <DeskingCardBlocksContainer>
                {!isPaymentDetailsInSidePanel && (
                    <DeskingCardBlock rowName="payment-details">
                        <DeskingCardPaymentDetailsAccordion />
                    </DeskingCardBlock>
                )}

                <DeskingCardBlock rowName="terms" rowNameEnd="trade">
                    <Card sx={TermsCardSx}></Card>
                </DeskingCardBlock>

                <DeskingCardBlock rowName="terms">
                    <Terms />
                </DeskingCardBlock>
                <DeskingCardBlock rowName="paymentGrid">{isPaymentGridEnabled && <GridTableInterstate />}</DeskingCardBlock>

                <DeskingCardBlock rowName="creditBureau">
                    <CreditBureau />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="trade">
                    <InterstateTradeFormCard isEditDisabled={!isPrimaryScope} />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="tax">
                    <TaxCard />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="fees">
                    <FeesCard />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="rebates">
                    <RebatesIndex />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="vpp">
                    <VehicleProtectionPreview />
                </DeskingCardBlock>

                <DeskingCardBlock rowName="accessories">
                    <InterstateAccessoriesCard />
                </DeskingCardBlock>
            </DeskingCardBlocksContainer>
        </StyledDeskingCard>
    );
});
