import numeral from 'numeral';
import { SearchableSelectOptions } from '@cx/ui/SearchableSelect';
import { dateUtils, formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { CASH, OfferType } from '@makemydeal/dr-platform-types';

export const termIdToOptionMapper = ({ id, months }: any): SearchableSelectOptions => ({
    value: id,
    label: `${months} Months`
});

export const formatAnnualMiles = (miles: number): string => `${numeral(miles).format('0,0')} Miles`;

export const parseSelectedTermMonths = (termId: string): string | undefined => {
    if (!termId) {
        return undefined;
    }
    const parts = termId.split('_');
    return parts[2];
};

export const addValueSuffix = (value: string): string => {
    if (value === '') {
        return value;
    }

    if (value === '1') {
        return `${value} Month`;
    }

    return `${value} Months`;
};

export const removeValueSuffix = (value: string): string => {
    return value.replace(' Months', '').replace(' Month', '');
};

export const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);
    return isoDate && dateUtils.isValidDate(date) ? dateUtils.formatUtcDateTimeToDateOnly(isoDate, 'mmddyyyy') : '';
};

export const formatSingleCardViewPaymentDetails = (currentOfferType: OfferType, monthlyPayment?: number) => {
    return currentOfferType !== CASH ? `${formatDollarsAndCents(monthlyPayment)}/mo` : '';
};

export const formatMultiCardViewPaymentDetails = (
    currentOfferType: OfferType,
    monthlyPayment?: number,
    cashAmountDue?: number,
    selectedTermId?: string
) => {
    // add indicator L C F for all offer types
    const offerTypeIndicator = currentOfferType[0].toUpperCase();
    let paymentValue = offerTypeIndicator;

    // if offer type is not cash, add term value
    if (currentOfferType !== CASH) {
        const months = parseSelectedTermMonths(selectedTermId || '');
        paymentValue += months || '';
    }

    // add payment value. for cash it is total, for finance/lease it is monthly payment
    // no need to check for cashAmountDue or monthlyPayment, formatDollarsAndCents will handle it
    if (currentOfferType === CASH) {
        paymentValue += ` - ${formatDollarsAndCents(cashAmountDue)}`;
    } else {
        paymentValue += ` - ${formatDollarsAndCents(monthlyPayment)}/mo`;
    }

    return paymentValue;
};
