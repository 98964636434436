import { useDispatch, useSelector } from 'react-redux';
import { OverrideMFItem, FINANCE } from '@makemydeal/dr-platform-types';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { Grid } from '@interstate/components/Grid';
import { NumericInput } from '@interstate/components/NumericInput';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { GridItem, StyledTypography } from './styled.components';

export const Override: React.FC = () => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const items: OverrideMFItem[] = useSelector(offerReduxSelectors.getAPROverrides);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);

    const handleChange =
        (month: number | null, currentSellRate?: number | null) => (e: InterstateOnChangeEvent<TextInputEventValue>) => {
            const value = e.target.value === '' ? null : parseFloat(e.target.value);
            if (!month || currentSellRate === value) return;

            logNewRelicPageAction('MV:PaymentGrid - Override sell rate', {
                mvOfferType: offerType,
                mvFieldEdited: 'mv:payment:grid:override-sell-rate',
                mvDealXgId: dealXgId
            });

            dispatch(offerReduxActionCreators.updateSellRateOverride(value, month));
        };

    return (
        <>
            <Grid xs={3} className="override-mf" textAlign="center">
                <StyledTypography className="large" style={{ color: 'black' }}>
                    Override {offerType === FINANCE ? 'APR' : 'MF'}
                </StyledTypography>
            </Grid>
            {items.map((item) => (
                <GridItem xs={3} key={item.key} paddingTop="5px" border="none">
                    <NumericInput
                        data-testid={`override-input-${item.type}-${item.month}`}
                        name={(item.month && item.month.toString()) || 'empty-term'}
                        value={`${item.val ?? ''}`}
                        label={offerType === FINANCE ? 'APR %' : 'MF'}
                        allowDecimal={true}
                        autoInsertCommas={false}
                        decimalMaxLength={6}
                        onBlur={handleChange(item.month, item.val)}
                    />
                </GridItem>
            ))}
        </>
    );
};
