// TODO: DASH - improve this pattern - it must be possible to re-use common logic for tradeIn, incentives and trade-in.
// Maybe we could actually do something in dr-activities-common for this and/or expose this type of middleware directly from the
// feature package (if that makes sense?)

// externals
import { Middleware, MiddlewareAPI } from 'redux';

// libraries
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
// TODO: DASH - update trade-in activity to use MiddlewareManager naming (or Middleware Factory?)
import { Middleware as MiddlewareManager, Actions } from '@makemydeal/dr-activities-tradein';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { historyInstance } from '@makemydeal/dr-shared-store';
import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import {
    configSelectors,
    navigationActionCreators,
    tradeShopperActivity,
    offerReduxActionCreators,
    tradeInActionCreators,
    tradeInCardActionCreators,
    tradeInCardSelectors,
    tradeActionTypes
} from '@makemydeal/dr-dash-store';
import { clientLogger, payloadUtils } from '@makemydeal/dr-shared-ui-utils';

// utils
import { relayExternalTrackPayload } from '../../common/services/analyticsRelay';

// interfaces/types
import type { ServiceEndpointConfig } from '../../types/serviceEndpointTypes';

// config
import { configureTradeIn } from './config';
import { getCurrentFlowName } from '../../flowMasterInterfaces';

// components
import { InitConfiguration } from '@makemydeal/dr-dash-bff-types';

// keep a cached version of the middleware once we're able to build it.
// exported to support testing
export let cachedTradeInMiddlewareMgr: MiddlewareManager | undefined;

// needed for testing
export const tradeInMiddlewareClearCache = () => (cachedTradeInMiddlewareMgr = undefined);

export const tradeInMiddleware: Middleware<unknown, StateTree> = (store: MiddlewareAPI) => (next: any) => (action: AnyFSA) => {
    switch (action.type) {
        case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
            // get what we can from state.  This typically will be the bff endpoint only since its placed as part of initial state
            const state = store.getState();
            const bff = configSelectors.getServicesBff(state);
            const kbbTradeInApiKey = configSelectors.getServicesKbb(state).tradeInApiKey || '';

            // now we will not have the toggles, static images or the gateway in state
            // so we will have to replace them
            const config = payloadUtils.getConfigFromInitPayload<InitConfiguration>(action.payload);
            const { staticImages, services: { gateway } = {} } = config;

            // we need staticImages to continue
            if (staticImages && gateway) {
                cachedTradeInMiddlewareMgr = configureTradeIn({
                    services: {
                        bff,
                        gateway
                    },
                    staticImages,
                    utils: {
                        buildApiUrlWithServiceEndpoint: ({ service = 'bff', endPoint = 'init' }: ServiceEndpointConfig): string => {
                            if (service !== 'bff') {
                                throw new Error('CMD buildApiUrlWithServiceEndpoint does not support services other than "bff"');
                            }
                            return urlBuilder.buildFromConfig(bff, endPoint);
                        },
                        getCurrentFlowName,
                        getCurrentLocation: () => {
                            return historyInstance.location.pathname;
                        }
                    },
                    // TODO: DASH - this appears to be other "host actions" - should move them under hostActions for consistency.
                    offerActionCreators: {
                        updatedShopper: offerReduxActionCreators.updatedShopper,
                        updatedTradeIn: offerReduxActionCreators.updatedTradeIn
                    },
                    hostActions: {
                        relayTradeInEvents: relayExternalTrackPayload
                    },
                    kbbTradeInApiKey,
                    // NOTE: Since we are not using the old menu, we don't need to provide it to trade activity.
                    renderMenuProducts: /* istanbul ignore next */ () => null
                });
            } else {
                clientLogger.clientWarnMessage('Could not bootstrap the tradeIn activity.');
            }

            break;
        }

        case tradeActionTypes.TRADE_DETAILS_MANUAL_ENTRY_SAVE: {
            const state = store.getState();
            const includesTradeIn = tradeInCardSelectors.getShouldIncludeTradeIn(state);

            store.dispatch(tradeInActionCreators.tradeDetailsManualEntrySaveTradeIn(state.tradeIn));
            store.dispatch(tradeInActionCreators.requestTradeInPaymentUpdate());

            if (!includesTradeIn) {
                store.dispatch(tradeInCardActionCreators.includeTradeChanged(true));
            }

            break;
        }

        case tradeActionTypes.TRADE_IN_PAYMENT_INFO_UPDATE_REQUEST: {
            const state = store.getState();
            const includesTradeIn = tradeInCardSelectors.getShouldIncludeTradeIn(state);

            if (includesTradeIn) {
                store.dispatch(tradeInActionCreators.updateTradeInPayment());
            }

            break;
        }

        case tradeActionTypes.TRADE_DETAILS_MANUAL_ENTRY_CANCEL: {
            const state = store.getState();
            store.dispatch(tradeInActionCreators.manualEntryCancelTradeIn(state.rollbackTradeIn));
            break;
        }

        /**
         * We need to prevent the api call made at the end of the trade flow that persists the trade.
         * Trade persistence is a part of Offer Save in CMD, which is not the case in AMD.
         *
         * So we must `return` at the end of this handler to prevent the rest of the trade activity's middleware
         * from being invoked with this action.
         */
        case Actions.TRADE_IN_PERFORM_UPDATE: {
            next(action);
            store.dispatch(tradeShopperActivity.tradeActionCreators.tradeFlowComplete());
            store.dispatch(navigationActionCreators.navigateToDashboard());
            return;
        }
    }

    if (cachedTradeInMiddlewareMgr) {
        cachedTradeInMiddlewareMgr.middleware(store)(next)(action);
    } else {
        next(action);
    }
};
