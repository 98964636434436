import React from 'react';
import LineItemWithChildren from './LineItemWithChildren';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

type LineItem = {
    key: string;
    label: string;
    value: string;
};

type LineItemsProps = {
    collection: LineItem[];
};

const LineItems: React.FC<LineItemsProps> = ({ collection }) => {
    return (
        <>
            {collection.map((item: LineItem, i: number) => {
                const { key, label, value } = item;
                return (
                    <LineItemWithChildren
                        data-testid={`${label}-lineItem`}
                        key={key}
                        label={label}
                        value={formatDollarsAndCents(value)}
                    />
                );
            })}
        </>
    );
};

export default LineItems;
