import { Badge } from '@interstate/components/Badge';
import { RadioButtonList } from '@interstate/components/RadioButtonList';
import { useDispatch } from 'react-redux';
import { scopedActionsCreators } from '@makemydeal/dr-shared-store';
import { DeskingCardSelectDraftDeal } from './DeskingCard.style';

export const DeskingCardDealLabel = ({ isPrimaryScope, scopeId }: { isPrimaryScope: boolean; scopeId: string }) => {
    const dispatch = useDispatch();

    return isPrimaryScope ? (
        <Badge variant="info" data-testid="primary-deal-label">
            <span>Primary</span>
        </Badge>
    ) : (
        <DeskingCardSelectDraftDeal>
            <RadioButtonList
                inline
                displayLabel={false}
                data-testid="draft-deal-select"
                options={[{ label: 'Select', value: scopeId }]}
                onChange={() => {
                    dispatch(scopedActionsCreators.changeDefaultScope(scopeId));
                }}
            ></RadioButtonList>
        </DeskingCardSelectDraftDeal>
    );
};
