// istanbul ignore file
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled, { css } from 'styled-components';
import { Badge } from '@interstate/components/Badge';

import { getMaxAppContainerWidth } from '../utils/getMaxAppContainerWidth';

export const ActivityRouteContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    padding: 0 8px;
`;

export type FlexGridColProps = {
    gap?: string;

    /**
     * No breakpoint; default. (Typically mobile)
     */
    cols?: number;
    /**
     * Small breakpoint
     */
    smCols?: number;
    mdCols?: number;

    alignItems?: string;
    alignContent?: string;
    justifyContent?: string;

    // placeholders
    // lgCols?: number
    // xlCols?: number
};

export const FlexGridCol = styled.div<FlexGridColProps>`
    display: grid;
    ${({ alignItems }) => (alignItems != null ? `align-items: ${alignItems};` : `align-items: end;`)}
    ${({ alignContent }) => (alignContent != null ? `align-content: ${alignContent};` : ``)}
    ${({ justifyContent }) => (justifyContent != null ? `justify-content: ${justifyContent};` : '')}
    ${({ gap }) => (gap != null ? `gap: ${gap};` : '')}

    ${({ cols }) => (cols != null ? `grid-template-columns: repeat(${cols}, minmax(0, 1fr));` : '')}

    ${({ smCols }) =>
        smCols != null
            ? `
    @media (min-width: ${BreakPoint.SMALL}) {
        grid-template-columns: repeat(${smCols}, minmax(0, 1fr));
    }`
            : ''}

    ${({ mdCols }) =>
        mdCols != null
            ? `
    @media (min-width: ${BreakPoint.MEDIUM}) {
        grid-template-columns: repeat(${mdCols}, minmax(0, 1fr));
    }`
            : ''}
`;

FlexGridCol.defaultProps = {
    gap: '1rem'
};

export type FlexGridRowProps = {
    rows?: number;
    smRows?: number;
    mdRows?: number;
    gap?: string;
    justifyContent?: string;
    alignContent?: string;
    alignItems?: string;
};
export const FlexGridRow = styled.div<FlexGridRowProps>`
    display: grid;
    align-items: center;

    ${({ gap }) => (gap != null ? `gap: ${gap};` : '')}
    ${({ rows }) => (rows != null ? `grid-template-rows: repeat(${rows}, minmax(0, 1fr));` : '')}
    ${({ alignItems }) => (alignItems != null ? `align-items: ${alignItems};` : '')}
    ${({ alignContent }) => (alignContent != null ? `align-content: ${alignContent};` : '')}
    ${({ justifyContent }) => (justifyContent != null ? `justify-content: ${justifyContent};` : '')}

    ${({ smRows }) =>
        smRows != null
            ? `
    @media (min-width: ${BreakPoint.SMALL}) {
        grid-template-rows: repeat(${smRows}, minmax(0, 1fr));
    }`
            : ''}
    ${({ mdRows }) =>
        mdRows != null
            ? `
    @media (min-width: ${BreakPoint.MEDIUM}) {
        grid-template-rows: repeat(${mdRows}, minmax(0, 1fr));
    }`
            : ''}
`;

FlexGridRow.defaultProps = {
    gap: '1rem'
};

export const FlexNone = styled.div`
    display: flex;
    flex: none;
    align-items: center;
    gap: 1rem;
`;

export const FlexRow = styled.div<{
    gap?: string;
    alignItems?: string;
    alignContent?: string;
    justifyContent?: string;
}>`
    ${({ gap }) => (gap != null ? `gap: ${gap};` : '')}
    ${({ alignItems }) => (alignItems != null ? `align-items: ${alignItems};` : '')}
    ${({ alignContent }) => (alignContent != null ? `align-content: ${alignContent};` : '')}
    ${({ justifyContent }) => (justifyContent != null ? `justify-content: ${justifyContent};` : '')}
    display: flex;
    flex-direction: row;
`;
FlexRow.defaultProps = {
    gap: '1rem'
};

export const FlexCol = styled.div<{ gap?: string; alignItems?: string; alignContent?: string; justifyContent?: string }>`
    height: 100%;
    display: flex;
    flex-direction: column;
    ${({ gap }) => (gap != null ? `gap: ${gap};` : '')}
    ${({ gap }) => (gap != null ? `gap: ${gap};` : '')}
    ${({ alignItems }) => (alignItems != null ? `align-items: ${alignItems};` : '')}
    ${({ alignContent }) => (alignContent != null ? `align-content: ${alignContent};` : '')}
    ${({ justifyContent }) => (justifyContent != null ? `justify-content: ${justifyContent};` : '')}
`;

FlexCol.defaultProps = {
    gap: '1rem'
};

export const StyledVAutoAppraisalStatus = styled(Badge)`
    margin-left: 0.5rem;
`;

export const ContainerGrid = styled.div<{
    $xsBreakpoint?: number;
    $mdBreakpoint?: number;
    $lgBreakpoint?: number;
    $gap?: string;
}>`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    container-name: ContainerGrid;
    container-type: inline-size;
    gap: ${(props) => (props.$gap ? props.$gap : '1rem')};

    // FALLBACK FOR BROWSERS WHO NOT SUPPORT CONTAINERS
    @media (min-width: 0px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-xs-${i + 1} {
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-md-${i + 1} {
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}
    }

    @media (min-width: ${BreakPoint.MEDIUM}) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-lg-${i + 1} {
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}
    }

    // MAIN CONTAINER
    @container (min-width: ${(props) => (props.$xsBreakpoint ? props.$xsBreakpoint : 0)}px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-xs-${i + 1} {
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}
    }

    @container (min-width: ${(props) => (props.$mdBreakpoint ? props.$mdBreakpoint : 450)}px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-md-${i + 1} {
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}
    }

    @container (min-width: ${(props) => (props.$lgBreakpoint ? props.$lgBreakpoint : 900)}px) {
        ${Array.from(
            { length: 12 },
            (_, i) => `
        .container-grid-item-lg-${i + 1} {
          flex: 1 calc(${(100 / 12) * (i + 1)}% - 1rem);
          max-width: calc(${(100 / 12) * (i + 1)}% - ${i === 11 ? '0rem' : '0.5rem'});
        }
      `
        ).join('')}
    }
`;
