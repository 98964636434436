import React from 'react';
import { useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import type { OfferType } from '@makemydeal/dr-platform-types';
import { Typography } from '@interstate/components/Typography';
import { rem } from 'polished';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { OfferCardDetailsAB } from './OfferCardDetailsAB';

export interface IOfferCardDetailsProps {
    offerType: OfferType;
    monthlyPayment?: number;
    netCashDown?: number;
    tradeAllowance?: number;
    offerPrice?: number;
    months?: number;
    apr?: number;
    sellRate?: number;
    vehicleTitle?: string;
    annualMiles?: number;
}

function renderPayment(offerType?: OfferType, monthlyPayment?: number, offerPrice?: number) {
    if (offerType === 'finance' || offerType === 'lease') {
        return (
            <Typography variant={'body-md'}>
                {`${formatDollarsAndCents(monthlyPayment)}`}
                <span>/mo</span>
            </Typography>
        );
    }
    if (offerType === 'cash') {
        return <Typography variant={'body-md'}>{`${formatDollarsAndCents(offerPrice)}`}</Typography>;
    }

    return null;
}

function renderTerms(offerType?: OfferType, months?: number, apr?: number, sellRate?: number) {
    if (offerType === 'finance') {
        return <Typography variant={'body-md'}>{`${months} mos at ${apr}% APR`}</Typography>;
    }
    if (offerType === 'lease') {
        return <Typography variant={'body-md'}>{`${months} mos at ${sellRate}% APR`}</Typography>;
    }

    return null;
}

export const OfferCardDetailsInterstate: React.FC<IOfferCardDetailsProps> = ({
    offerType,
    monthlyPayment,
    netCashDown,
    tradeAllowance,
    offerPrice,
    months,
    apr,
    sellRate,
    vehicleTitle,
    annualMiles
}: IOfferCardDetailsProps) => {
    const isABPencilStateMVEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const formattedOfferType = offerType.charAt(0).toUpperCase() + offerType.slice(1);

    return isABPencilStateMVEnabled ? (
        /**
         * OfferCardDetailsAB was created to keep code coverage at 100%.
         * Since this OfferCardDetailsInterstate component is being used in multiple places,
         * OfferCardDetailsAB is being used here not up levels in the component tree to effect the changes.
         */
        <OfferCardDetailsAB
            offerType={offerType}
            monthlyPayment={monthlyPayment}
            netCashDown={netCashDown}
            tradeAllowance={tradeAllowance}
            offerPrice={offerPrice}
            months={months}
            apr={apr}
            vehicleTitle={vehicleTitle}
            annualMiles={annualMiles}
        />
    ) : (
        <div data-testid="offer-card-details">
            {formattedOfferType ? (
                <Typography variant={'h5'} sx={{ padding: `${rem(8)} 0` }}>{`${formattedOfferType}`}</Typography>
            ) : (
                <div />
            )}
            {renderPayment(offerType, monthlyPayment, offerPrice)}
            {renderTerms(offerType, months, apr, sellRate)}
            <div>
                {offerType !== 'cash' && (
                    <Typography variant={'body-md'}>{`${formatDollarsAndCents(netCashDown ?? 0)} Cash Down`}</Typography>
                )}
                {tradeAllowance && tradeAllowance > 0 ? (
                    <Typography variant={'body-md'}>{`${formatDollarsAndCents(tradeAllowance)} Trade Allowance`}</Typography>
                ) : null}
            </div>
        </div>
    );
};

export default OfferCardDetailsInterstate;
