import { Route } from 'react-router-dom';

import { IAccessoriesCatalogActivity } from '../types/IAccessoriesCatalogActivity';
import { getAllRoutes } from '../utils/routes';

import AccessoriesCatalogActivity from './AccessoriesCatalogActivity';
import { ActivityRouteContainer } from './AccessoriesCatalogActivity.style';

const Root = (props: IAccessoriesCatalogActivity) => {
    const { root } = getAllRoutes(props.baseRoute);

    return (
        <ActivityRouteContainer data-testid="activities-accessories-catalog-container" className="activities-accessories-catalog">
            <Route path={root} component={AccessoriesCatalogActivity} />
        </ActivityRouteContainer>
    );
};

Root.displayName = 'AccessoriesCatalogActivity';

export default Root;
