import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Typography } from '@interstate/components/Typography';
import { SelectInputEventValue } from '@interstate/components/SelectInput';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';
import { tradeInComponentSelectors, tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// components
import { SelectInput } from './TradeInSelectInput';

// styling
import { DetailContainer } from '../trade.interstate.style';

// consts
import { VEHICLE_CONDITION } from '../../../constants';
import { TRADE_IN_CONDITION } from '@makemydeal/dr-dash-components';

interface VehicleConditionFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const VehicleConditionField: React.FC<VehicleConditionFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleCondition = useSelector(tradeInSelectors.getCondition);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);

    const getVehicleConditionOptions = () => VEHICLE_CONDITION.map((condition) => ({ label: condition, value: condition })).sort();

    const onConditionChange = (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
        const condition = event.target.value as string;
        dispatch(tradeInActionCreators.updateTradeInCondition({ condition }));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                condition
            })
        );
    };

    return (
        <>
            {isManualFormOpen ? (
                <SelectInput
                    data-testid="trade-condition-select-input"
                    label={TRADE_IN_CONDITION}
                    name={TRADE_IN_CONDITION}
                    onChange={onConditionChange}
                    options={getVehicleConditionOptions()}
                    value={vehicleCondition}
                    placeholder="Select"
                    displayDeselectOption={true}
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {TRADE_IN_CONDITION}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-vehicle-condition-value-label">
                                {vehicleCondition}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};

export default VehicleConditionField;
