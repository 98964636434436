import styled from 'styled-components';
import { Grid } from '@interstate/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const GridItem = styled(Grid)`
    border: 1px solid #b2b2b2;
    width: 120px;
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    &.MuiGrid2-root {
        padding: 0 20px;
    }
    @media (max-width: ${BreakPoint.SMALL}) {
        &.MuiGrid2-root {
            padding: 0 5px;
        }
    }
`;

export const StyledGridItem = styled(GridItem)<{ isSelected?: boolean }>`
    background-color: ${(props) => (props.isSelected ? '#003468' : '#ffffff')};
    color: ${(props) => (props.isSelected ? 'white' : '#16171A')};
    cursor: pointer;
`;

export const StyledTypography = styled.div`
    font-size: 12px;
    &.large {
        font-size: 16px;
    }
`;

export const LoadingIndicatorWrapper = styled.div`
    position: relative;

    & #interstitial-overlay-root {
        background-color: transparent;
    }
    & #interstitial-content-root {
        box-shadow: none;
    }
`;
