import { nanoid } from 'nanoid';
import { combineReducers } from 'redux';

import { RealState, ScopeMap, Scope } from '../types/scopeState';
import { getTargetScopeFromAction } from '../actions/scopedActions';
import {
    SCOPES_CHANGE_DEFAULT_SCOPE,
    SCOPES_CLONE_SCOPE,
    SCOPES_DELETE_SCOPE,
    SCOPES_SET_NAVIGATION_SCOPE_TARGET
} from '../actionTypes/scopedActionTypes';
import * as rollbackActionTypes from '../actionTypes/rollbackActionTypes';

const initialId = nanoid();

const initialState: RealState = {
    __global: {},
    __primaryScope: initialId,
    __navigationScopeTarget: initialId,
    __isScoped: true,
    __scopeMap: {
        order: [initialId],
        scopes: {
            [initialId]: {
                __id: initialId,
                __scopeState: {},
                __scopeMeta: {
                    deleted: false
                }
            }
        }
    }
};

const isStateInitialized = (state: Partial<RealState>) => {
    return Boolean(state.__global && state.__primaryScope && state.__navigationScopeTarget && state.__isScoped && state.__scopeMap);
};

export const createRootScopedReducer = (globalReducers: any, scopedReducers: any) => {
    const combinedGlobalReducers = combineReducers<any, any>(globalReducers);
    const scopesReducer = createScopesReducer(scopedReducers);

    return (state: any = initialState, action: any): RealState => {
        // process global reducers
        const globalState = combinedGlobalReducers(state.__global, action);
        const globalStateHasChanged = globalState !== state.__global;

        // process scoped reducers
        const scopesState = scopesReducer(state.__scopeMap, action);
        const scopesStateHasChanged = scopesState !== state.__scopeMap;

        const shouldInitializeState = !isStateInitialized(state);

        // We can't rely on @@init action for initialization
        // https://github.com/reduxjs/redux/issues/186
        // https://github.com/reduxjs/redux/pull/259
        const initializedState = shouldInitializeState
            ? { ...initialState, ...state, __global: globalState, __scopeMap: scopesState }
            : state;

        switch (action.type) {
            case SCOPES_CHANGE_DEFAULT_SCOPE: {
                return {
                    ...initializedState,
                    __primaryScope: action.payload.newDefaultScope
                };
            }
            case SCOPES_SET_NAVIGATION_SCOPE_TARGET: {
                return {
                    ...initializedState,
                    __navigationScopeTarget: getTargetScopeFromAction(action)
                };
            }
            default: {
                // Only create a new object if there's actually a change.
                if (shouldInitializeState || globalStateHasChanged || scopesStateHasChanged) {
                    return {
                        ...initializedState,
                        __global: globalState,
                        __scopeMap: scopesState
                    };
                }

                return state;
            }
        }
    };
};

/**
 * Creates the reducer that affects the state of the Scope Map
 */
const createScopesReducer = (scopedReducers: any) => {
    const combinedScopedReducers = combineReducers<any, any>(scopedReducers);
    const scopeReducer = createScopeReducer(combinedScopedReducers);

    return (state: ScopeMap = initialState.__scopeMap, action: any): ScopeMap => {
        switch (action.type) {
            case SCOPES_DELETE_SCOPE: {
                const { scopeToDelete, metadata } = action.payload;

                return {
                    ...state,
                    order: state.order.filter((id) => id !== scopeToDelete),
                    scopes: {
                        ...state.scopes,
                        [scopeToDelete]: {
                            ...state.scopes[scopeToDelete],
                            __scopeMeta: { ...state.scopes[scopeToDelete].__scopeMeta, ...metadata, deleted: true }
                        }
                    }
                };
            }
            case SCOPES_CLONE_SCOPE: {
                const { scopeToClone, metadata } = action.payload;
                const clonedScope = structuredClone(state.scopes[scopeToClone].__scopeState);
                const newId = nanoid();

                return {
                    ...state,
                    order: [...state.order, newId],
                    scopes: {
                        ...state.scopes,
                        [newId]: {
                            __id: newId,
                            __scopeState: clonedScope,
                            __scopeMeta: { deleted: false, ...metadata }
                        }
                    }
                };
            }
            default: {
                const targetScope = getTargetScopeFromAction(action);
                const scopeState = targetScope !== undefined && state.scopes[targetScope];

                if (scopeState) {
                    const newScopeState = scopeReducer(scopeState, action);

                    if (scopeState !== newScopeState) {
                        const newState = {
                            ...state,
                            scopes: {
                                ...state.scopes,
                                [targetScope]: newScopeState
                            }
                        };

                        return newState;
                    }
                }

                return state;
            }
        }
    };
};

/**
 * Creates Reducer that affects only the state of a single scope
 */
const createScopeReducer = (combinedScopedReducers: any) => {
    return (state: Scope, action: any): Scope => {
        // Run the action on the Scoped Reducers to get the new state for this scope.
        const scopeState = combinedScopedReducers(state.__scopeState, action);
        const scopesStateHasChanged = scopeState !== state.__scopeState;

        switch (action.type) {
            case rollbackActionTypes.PREPARE_SCOPE_STATE_COPY: {
                return {
                    ...state,
                    __scopeState: scopeState,
                    __rollbackState: state.__scopeState
                };
            }
            case rollbackActionTypes.ROLLBACK_SCOPE_STATE: {
                const rollbackState = state.__rollbackState;
                if (!rollbackState) {
                    return state;
                }

                const persistData = action.payload?.persistData || [];
                const scopeStateToPersist = persistData.reduce(
                    (state: Record<string, any>, key: string) => ({
                        ...state,
                        [key]: scopeState[key]
                    }),
                    {} as Record<string, any>
                );

                return { ...state, __rollbackState: undefined, __scopeState: { ...rollbackState, ...scopeStateToPersist } };
            }
            // Right now it's not possible to edit scope metadata.
            // There's no use-case for it yet.
            default: {
                // Only create a new object if there's actually a change.
                if (scopesStateHasChanged) {
                    return {
                        ...state,
                        __scopeState: scopeState
                    };
                }

                return state;
            }
        }
    };
};
