/* eslint-disable react-hooks/rules-of-hooks */
// externals
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { bootstrapSharedActionCreators, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import {
    dealHistoryActionCreators,
    dealHistoryActionTypes,
    dealHistorySelectors,
    quickCompareActionCreators,
    offerInfoSelectors
} from '@makemydeal/dr-dash-store';

// components
import { Card, CardActions } from '@interstate/components/Card';
import { Tooltip } from '@interstate/components/Tooltip';
import QuickCompareWithInterstate from '../quickCompare/QuickCompare.interstate';
import { OfferMessage } from '../offerMessage/OfferMessage.interstate';
import { OfferCardHeader } from '../offerCardHeader/OfferCardHeader.interstate';
import MakeCurrentModal from '../makeCurrentModal/MakeCurrentModal.interstate';

// styles
import { OfferBadge, StyledABPencilOfferCardContainer, StyledOfferCardWrapper } from './OfferCard.style.interstate';

// types
import type { IPencilOfferInputs, IShopper } from '@makemydeal/dr-platform-types';

// utils
import { dateUtils } from '@makemydeal/dr-common-utils';

import OfferCardDetailsInterstate from '../offerCardDetails/OfferCardDetails.interstate';
import { CheckBox } from '@interstate/components/CheckBox';
import { Box } from '@interstate/components/Box';
import { rem } from 'polished';
import { Grid } from '@interstate/components/Grid';
import { MakeCurrentButtonView } from '../makeCurrentModal/types';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { Typography } from '@interstate/components/Typography';
import { Badge } from '@interstate/components/Badge';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

/**
 * hasMultiScenarios is for AB Pencil only.
 * Not practically in use currently, but indicating to stack card ui. Can be handled in this component when logic is implemented.
 */
export interface IOfferCardProps {
    deal?: DealHistory;
    isDealHistory?: boolean;
    isDefaultOffer?: boolean;
    isMostRecentDealerOffer?: boolean;
    isMostRecentShopperOffer?: boolean;
    hasMultiScenarios?: boolean;
}

export const DISABLED_CHECKBOX_MESSAGE = "You've reached your max comparison, uncheck one of the other cards to continue";

export const OfferCardInterstate: React.FC<IOfferCardProps> = ({
    deal,
    isDealHistory,
    isDefaultOffer,
    isMostRecentDealerOffer,
    isMostRecentShopperOffer,
    hasMultiScenarios = false
}: IOfferCardProps) => {
    if (!deal) {
        return null;
    }

    const theme = useInterstateTheme();
    const dispatch = useDispatch();
    const selectedDeals = useSelector(dealHistorySelectors.getSelectedDealHistory);
    const socketEventDealVersion = useSelector(offerInfoSelectors.getSocketEventDealVersion);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    const { offer } = deal;
    const {
        dealXgVersion,
        shopper,
        createdOn,
        offerType,
        terms,
        offerSource,
        applicationName,
        penciledInputs,
        accepted,
        messageToDealer,
        vehicle,
        cmdMessageToShopper: dealerMessage
    } = offer;
    const { firstName, lastName } = shopper as IShopper;
    const { stockNumber, condition, make, model, year, trim } = vehicle;
    const stockNumberValue = stockNumber ? `#${stockNumber}` : '--';
    const vehicleTitle = `${condition} ${year} ${make} ${model} ${trim}`;
    const createdOnFormattedDate = createdOn && dateUtils.formatAsMMDDYYYY(new Date(createdOn));
    const createdOnFormattedTime = createdOn && dateUtils.formatAsTime(new Date(createdOn));
    const { term, offerPrice, annualMiles } = (offerType && penciledInputs?.byOfferType?.[offerType]) ?? ({} as IPencilOfferInputs);
    const payment = term && terms.byId[term];
    const isDealUpdated = dealXgVersion === socketEventDealVersion;

    const updateQuickCompareOffer = useCallback(() => {
        dispatch(quickCompareActionCreators.updateQuickCompareOffer(deal as DealHistory));
    }, [dispatch, deal]);

    const makeCurrentOffer = useCallback(() => {
        dispatch({ type: dealHistoryActionTypes.FETCH_DEAL_HISTORY_REQUEST });
        const fetchBootstrap = bootstrapSharedActionCreators.fetchBootstrapData(MANAGER_VIEW_APP_PREFIX, {
            withCurrentDealVersion: true,
            dealXgVersion
        });
        dispatch(fetchBootstrap as any);
    }, [dispatch, dealXgVersion]);

    const dealCheckedChange = useCallback(
        (event: any) => {
            if (event.target.value.checked) {
                dispatch(dealHistoryActionCreators.dealHistoryChecked(deal));
                dispatch(dealHistoryActionCreators.dealHistorySelectedUpdate(offer.offerId as string));
            } else {
                dispatch(dealHistoryActionCreators.dealHistoryUnchecked(offer.offerId as string));
            }
        },
        [dispatch, deal, offer.offerId]
    );

    const isCheckboxDisabled = useMemo(() => {
        const isDealSelected = selectedDeals.find((selectedDeal) => selectedDeal.offer.offerId === offer.offerId);
        return !isDealSelected && selectedDeals.length === 2;
    }, [selectedDeals, offer.offerId]);

    const renderCheckbox = () => {
        const checkboxId = 'deal-compare-checkbox-' + deal.offer.offerId;
        return isCheckboxDisabled ? (
            <Tooltip
                id="tooltipLink"
                toolTipContent={DISABLED_CHECKBOX_MESSAGE}
                data-testid="disabled-offer-card-tooltip-interstate"
                size="large"
                position="bottom"
            >
                <div>
                    <CheckBox
                        id={checkboxId}
                        name="checkBoxList"
                        onChange={dealCheckedChange}
                        value="1"
                        label={'Compare'}
                        disabled
                    />
                </div>
            </Tooltip>
        ) : (
            <CheckBox id={checkboxId} name="checkBoxList" onChange={dealCheckedChange} value="1" label={'Compare'} />
        );
    };

    const renderOfferCardDetails = () => {
        return (
            offerType && (
                <OfferCardDetailsInterstate
                    offerType={offerType}
                    monthlyPayment={payment?.monthlyPayment}
                    netCashDown={payment?.netCashDown}
                    tradeAllowance={payment?.totalAllowance}
                    {...(offerPrice && { offerPrice })}
                    months={payment?.months}
                    apr={payment?.apr}
                    sellRate={payment?.sellRate}
                    {...(isABPencilEnabled && { vehicleTitle, annualMiles })}
                />
            )
        );
    };

    const renderOfferCardActions = () => {
        return (
            <>
                {!isDealHistory && (
                    <>
                        <QuickCompareWithInterstate onCompareClick={updateQuickCompareOffer} />
                        <MakeCurrentModal
                            onMakeCurrentOffer={makeCurrentOffer}
                            makeCurrentButtonView={MakeCurrentButtonView.DealComparison}
                        />
                    </>
                )}
                {isDealHistory && (
                    <div data-testid="dr-dash-offer-card-compare" className="offer-card-compare-check-box">
                        {renderCheckbox()}
                    </div>
                )}
            </>
        );
    };

    /**
     * There is also renderABPencilOfferCard function below that is almost identical to renderOfferCard function.
     * Reason is it would be hard to read to keep one and conditionally apply the rules based on the isABPencilEnabled flag.
     */
    const renderOfferCard = () => {
        return (
            <Card
                className="offer-card-container"
                data-testid="offer-card-interstate"
                sx={{ position: 'relative' }}
                content={
                    <Grid gap={1} container flexDirection="column" minHeight="131px">
                        {accepted && (
                            <OfferBadge id="accepted-offer-badge" variant="success">
                                Accepted
                            </OfferBadge>
                        )}
                        {!accepted && isDealUpdated && (
                            <OfferBadge id="updated-offer-badge" variant="success">
                                Updated
                            </OfferBadge>
                        )}
                        {renderOfferCardDetails()}

                        <Box sx={{ paddingTop: `${rem(8)}` }}>
                            <OfferMessage
                                shopperMessage={messageToDealer}
                                dealerMessage={dealerMessage}
                                offerSource={offerSource}
                            />
                        </Box>
                    </Grid>
                }
                footer={<CardActions>{renderOfferCardActions()}</CardActions>}
            />
        );
    };

    const renderABPencilOfferCard = () => {
        return (
            <StyledABPencilOfferCardContainer theme={theme}>
                <div className="offer-card-ab-pencil offer-card-container ">
                    <div className="offer-card-body">
                        <div className="content-wrapper">
                            <div className="header">
                                <Typography variant="h6">Stock {stockNumberValue}</Typography>
                                <div className="badge-container">
                                    <Badge variant="info" id="primary-offer-badge">
                                        Primary
                                    </Badge>
                                    {hasMultiScenarios && (
                                        // badge value can be based on the count of the scenarios
                                        <Badge
                                            variant="transparent"
                                            id="scenario-count-offer-badge"
                                            className="badge-scenario-count"
                                        >
                                            +2
                                        </Badge>
                                    )}
                                    {accepted && (
                                        <Badge id="accepted-offer-badge" variant="success">
                                            Accepted
                                        </Badge>
                                    )}
                                    {!accepted && isDealUpdated && (
                                        <Badge id="updated-offer-badge" variant="success">
                                            Updated
                                        </Badge>
                                    )}
                                </div>
                            </div>

                            <div className="content">{renderOfferCardDetails()}</div>

                            <div className="message">
                                <OfferMessage
                                    shopperMessage={messageToDealer}
                                    dealerMessage={dealerMessage}
                                    offerSource={offerSource}
                                />
                            </div>
                        </div>
                        <div className={`actions ${hasMultiScenarios ? 'actions-shrink' : ''}`}>{renderOfferCardActions()}</div>
                    </div>
                    {hasMultiScenarios && (
                        <div className="stack-container">
                            {/* This stack divs can be as the count of the scenarios */}
                            <div className="stack-1"></div>
                            <div className="stack-2"></div>
                        </div>
                    )}
                </div>
            </StyledABPencilOfferCardContainer>
        );
    };

    return (
        <StyledOfferCardWrapper
            data-testid="dr-dash-offer-card"
            className={`offer-card ${isABPencilEnabled ? 'offer-card__wide' : ''}`}
        >
            <Box paddingBottom="12px" className={isABPencilEnabled ? 'offer-card-header' : ''}>
                <OfferCardHeader
                    shopperFirstName={firstName}
                    shopperLastName={lastName}
                    createdOnDate={createdOnFormattedDate}
                    createdOnTime={createdOnFormattedTime}
                    offerSource={offerSource}
                    applicationName={applicationName}
                    isDefaultOffer={isDefaultOffer}
                    isMostRecentDealerOffer={isMostRecentDealerOffer}
                    isMostRecentShopperOffer={isMostRecentShopperOffer}
                />
            </Box>

            {isABPencilEnabled ? renderABPencilOfferCard() : renderOfferCard()}
        </StyledOfferCardWrapper>
    );
};

export default OfferCardInterstate;
