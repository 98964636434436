// externals
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NumericInput } from '@interstate/components/NumericInput';
import { ToggleGroup } from '@interstate/components/ToggleGroup/ToggleGroup/ToggleGroup';

// libraries
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import {
    deskingSelectors,
    offerActionTypes,
    offerReduxActionCreators,
    offerReduxSelectors,
    offerSelectors
} from '@makemydeal/dr-dash-store';
import { ResidualValues } from '@makemydeal/dr-offer-redux';

import { LEASE, RESIDUAL_DOLLAR, RESIDUAL_PERCENT, ResidualValueType } from '@makemydeal/dr-platform-types';

// consts/enums
import { ADJUSTED_RESIDUAL, DEBOUNCE_TIMER, GENERIC_LAST_FIELD_CHANGED_ERROR } from '../../constants';

// utils
import { isEqual } from 'lodash';
import { convertToNumber, formatAprRate } from '../../utils/formatUtils';
import {
    StyledTextWithToggleGroupContainer,
    StyledTextWithToggleGroupText,
    StyledTextWithToggleGroupToggle
} from './StyledTextWithToggleGroup.styles';

const AdjustedResidualField = () => {
    const dispatch = useDispatch();
    const monthsSelector = (state: any) => {
        const term = offerReduxSelectors.getSelectedTermByOfferType(state, LEASE);
        return term?.months;
    };
    const months = useSelector(monthsSelector);

    // Grab current values from the redux store based on offerType and months
    const { adjustedResidualType, adjustedResidualAmount, adjustedResidualPercent } = useSelector(
        offerSelectors.getResidualInfoOverrideWithFallback,
        isEqual
    );

    const [unitType, setUnitType] = useState(adjustedResidualType as ResidualValueType);

    const isUnitTypePercentage = (): boolean => {
        return unitType === RESIDUAL_PERCENT;
    };

    const getPrefixByType = (percent?: boolean): string => {
        if (percent == null) {
            return isUnitTypePercentage() ? '%' : '$';
        }
        return percent ? '%' : '$';
    };

    const getPlaceholdeByType = (): string => {
        return isUnitTypePercentage() ? '0.00000' : '0.00';
    };

    const getFormatted = (value: number): string => {
        return isUnitTypePercentage() ? formatAprRate(value) : formatDollarsAndCents(value);
    };

    const textFormatter = useCallback(
        (value: number): string => {
            return getFormatted(value);
        },
        [unitType]
    );

    const selectedValue = useMemo(() => {
        const value = isUnitTypePercentage() ? adjustedResidualPercent : adjustedResidualAmount;
        return value;
    }, [unitType, adjustedResidualAmount, adjustedResidualPercent]);

    const [text, setText] = useState(textFormatter(selectedValue));

    // NOTE: due to the debouncing, etc this function isn't getting coverage credit, so ignore...
    /* istanbul ignore next */
    function actionCaller(residualValues: ResidualValues, makePaymentCall: boolean = true) {
        // we're only going to dispatch one action when the value itself ischanged. But the type will persist alongside the value.
        dispatch(offerReduxActionCreators.updateAdjustedResidualOverride(residualValues, makePaymentCall));
    }

    // NOTE: we're not using useDebounce because it is memoized on construction; we need to dememoize it here for access to current values in state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedActionCaller = useCallback(debounce(actionCaller, DEBOUNCE_TIMER), []);

    // change the unit type, but don't trigger payment call.
    function handleChangeUnitType(newType: ResidualValueType) {
        setUnitType(newType);
    }

    const handleTextChanged = useCallback(
        (e: any) => {
            setText(e.target.value);
            const value = convertToNumber(e.target.value);
            debouncedActionCaller({ value, months, unitType }); // delayed with payment call
        },
        [months, unitType]
    );

    const failedPercent = useSelector(
        deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_ADJUSTED_RESIDUAL_PERCENT_OVERRIDE)
    );
    const failedDollar = useSelector(
        deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_ADJUSTED_RESIDUAL_DOLLAR_OVERRIDE)
    );
    const failedMessage = useMemo(
        () => (failedPercent || failedDollar ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined),
        [failedPercent, failedDollar]
    );

    // NOTE: This function IS being tested, but it's not getting coverage credit, so ignore...
    /* istanbul ignore next */
    function handleBlur() {
        const value = convertToNumber(text);
        if (!isNaN(value)) setText(textFormatter(value));
    }

    // This effect will keep payment responses and term switching in sync with this component's offline input state.
    useEffect(() => {
        // istanbul ignore next
        if (unitType !== adjustedResidualType) setUnitType(adjustedResidualType);
        if (adjustedResidualType === RESIDUAL_DOLLAR) setText(textFormatter(adjustedResidualAmount));
        else setText(textFormatter(adjustedResidualPercent));
    }, [adjustedResidualAmount, adjustedResidualPercent, adjustedResidualType]);

    // toggle value when unit type changes
    useEffect(() => {
        setText(textFormatter(selectedValue));
        if (unitType !== adjustedResidualType) actionCaller({ months, unitType }, false); // immediate; without payment call
    }, [unitType]);

    return (
        <StyledTextWithToggleGroupContainer>
            <StyledTextWithToggleGroupText>
                <NumericInput
                    errorMessage={failedMessage}
                    hasError={Boolean(failedMessage)}
                    id="adjResidualValue"
                    data-testid="adjResidualValue"
                    label={ADJUSTED_RESIDUAL}
                    name={kebabCase(ADJUSTED_RESIDUAL)}
                    onChange={handleTextChanged}
                    onBlur={handleBlur}
                    value={text}
                    placeholder={getPlaceholdeByType()}
                    allowDecimal={true}
                    decimalMaxLength={3}
                    decimalMinLength={2}
                    inputPrefix={getPrefixByType()}
                    autoInsertCommas={true}
                />
            </StyledTextWithToggleGroupText>
            <StyledTextWithToggleGroupToggle>
                <ToggleGroup
                    size="medium"
                    id="adjResidualToggleGroup"
                    data-testid="adjResidualToggleGroupId"
                    toggleButtonOptions={[
                        {
                            children: <span>$</span>,
                            value: getPrefixByType(false),
                            selected: !isUnitTypePercentage(),
                            id: 'dollarToggle',
                            'data-testid': 'adjResidualDollarTypeToggle',
                            onClick: (e: any) => {
                                handleChangeUnitType(RESIDUAL_DOLLAR);
                            }
                        },
                        {
                            children: <span>%</span>,
                            value: getPrefixByType(true),
                            selected: isUnitTypePercentage(),
                            id: 'percentToggle',
                            'data-testid': 'adjResidualPercentTypeToggle',
                            onClick: (e: any) => {
                                handleChangeUnitType(RESIDUAL_PERCENT);
                            }
                        }
                    ]}
                    toggleWidth="60px"
                />
            </StyledTextWithToggleGroupToggle>
        </StyledTextWithToggleGroupContainer>
    );
};

export default AdjustedResidualField;
