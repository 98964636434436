import { useDispatch, useSelector } from 'react-redux';

// selectors
import { tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';

// constants
import { BODY_STYLE } from '../../../constants';

// components
import { TextInput } from '@interstate/components/TextInput';
import { Typography } from '@interstate/components/Typography';

// styling
import { DetailContainer } from '../trade.style';

interface BodyStyleFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const BodyStyleField: React.FC<BodyStyleFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const bodyStyle = useSelector(tradeInSelectors.getBodyStyle);

    const onChange = (event: any) => {
        dispatch(tradeInActionCreators.updateTradeInBodyStyle(event.target.value));
    };

    return (
        <>
            {isManualFormOpen ? (
                <TextInput
                    label={BODY_STYLE}
                    data-testid={kebabCase(BODY_STYLE)}
                    name={kebabCase(BODY_STYLE)}
                    onChange={onChange}
                    value={bodyStyle}
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {BODY_STYLE}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-body-style-value-label">
                                {bodyStyle}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};
