// hook
import { useSelector } from 'react-redux';

// types
import { DealHistory } from '@makemydeal/dr-dash-types';
import { FC } from 'react';

// selectors
import { accessoriesSelectors, offerReduxSelectors, offerSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// components
import DealSummaryItem from '../common/DealSummaryItem';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents, formatNumber } from '@makemydeal/dr-common-utils';
import { labelStyles, expandibleButtonStyles, expandibleAreaStyles, valueStyles, secondaryValueStyles } from '../utils/styles';
import { formatAprRate } from '../../utils/formatUtils';
import { isEqual } from 'lodash';

export type AdjustedResidualProps = {
    deal?: DealHistory;
};

const AdjustedResidual: FC<AdjustedResidualProps> = ({ deal }) => {
    const useDealRootSelector = createDealRootSelector(deal);

    const {
        baseResidualAmount: baseResidual,
        baseResidualPercent,
        adjustedResidualAmount,
        adjustedResidualPercent
    } = useSelector(offerSelectors.getResidualInfoOverrideWithFallback, isEqual);

    const formattedAdjustedResidual = formatDollarsAndCents(adjustedResidualAmount);
    const formattedAdjustedResidualPercent = `${formatAprRate(adjustedResidualPercent)}%`;
    const formattedBaseResidual = formatDollarsAndCents(baseResidual);
    const formattedBaseResidualPercent = `${formatAprRate(baseResidualPercent)}%`;

    const milesPerYear = useDealRootSelector(offerReduxSelectors.getAnnualMilesByTerm);
    const existingMileage = useDealRootSelector(vehicleSelectors.getMileage);
    const accessories = useSelector(accessoriesSelectors.getAccessoriesList);
    const accessoriesWithResidualValues = accessories
        .filter((accessory) => accessory.residual > 0)
        .map((accessory) => ({
            name: accessory.name,
            amount: accessory.residual
        }));
    const residualizedAccessoriesValue = accessoriesWithResidualValues.reduce((acc, accessory) => acc + accessory.amount, 0);

    const formattedResidualizedAccessoriesValue = formatDollarsAndCents(residualizedAccessoriesValue);

    return (
        <DealSummaryItem
            valueStyles={valueStyles}
            labelStyles={labelStyles}
            label={constants.TOTAL_ADJUSTED_RESIDUAL}
            value={formattedAdjustedResidual}
        >
            <LineItemWithChildren label={constants.BASE_RESIDUAL_PERCENT} value={formattedBaseResidualPercent} />
            <LineItemWithChildren label={constants.BASE_RESIDUAL} value={formattedBaseResidual} />
            <LineItemWithChildren label={constants.MILES_PER_YEAR} value={formatNumber(milesPerYear)} />
            <LineItemWithChildren label={constants.EXISTING_MILEAGE} value={formatNumber(existingMileage)} />
            <LineItemWithChildren label={constants.ADJUSTED_RESIDUAL_PERCENT} value={formattedAdjustedResidualPercent} />
            <LineItemWithChildren label={constants.ADJUSTED_RESIDUAL_DOLLAR} value={formattedAdjustedResidual} />
            {!!accessoriesWithResidualValues.length && (
                <DealSummaryItem
                    valueStyles={secondaryValueStyles}
                    labelStyles={labelStyles}
                    label={constants.RESIDUALIZED_ACCESSORIES}
                    value={formattedResidualizedAccessoriesValue}
                    expandibleButtonStyles={expandibleButtonStyles}
                    expandibleAreaStyles={expandibleAreaStyles}
                >
                    {accessoriesWithResidualValues.map((accessoryResidual) => (
                        <LineItemWithChildren
                            key={accessoryResidual.name}
                            label={accessoryResidual.name}
                            value={formatDollarsAndCents(accessoryResidual.amount)}
                        />
                    ))}
                </DealSummaryItem>
            )}
        </DealSummaryItem>
    );
};

export default AdjustedResidual;
