import styled from 'styled-components';
import { List } from '@interstate/components/List';

interface StyledListProps {
    hasDelta?: boolean;
}

export const StyledList = styled(List)<StyledListProps>`
    .ids-list-items-container > .ids-list-group-item > div {
        border: none;
        padding: 0;
    }
`;
