// externals
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { formatMultiCardViewPaymentDetails } from '@makemydeal/dr-dash-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@interstate/components/AccordionGroup';
import { Typography } from '@interstate/components/Typography';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import {
    deskingGlobalActionCreators,
    deskingGlobalSelectors,
    offerAdditionalSelectors,
    offerReduxSelectors,
    offerSelectors
} from '@makemydeal/dr-dash-store';

import InterstateDealSummary from '../dealSummary/DealSummary.interstate';
import { StyledPaymentTitle } from './DerskingCardTitle.style';

export const DeskingCardPaymentDetailsAccordion = () => {
    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const selectedTermId = useSelector((state) => offerReduxSelectors.getTermIdByOfferType(state, currentOfferType));
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);
    const cashAmountDue = useSelector(offerAdditionalSelectors.getMvCashAmountDue);
    const paymentDetailsAccordionExpanded = useSelector(deskingGlobalSelectors.getPaymentDetailsAccordionExpanded);

    const paymentDetailLabel = useMemo(() => {
        return formatMultiCardViewPaymentDetails(currentOfferType, monthlyPayment, cashAmountDue, selectedTermId);
    }, [currentOfferType, monthlyPayment, cashAmountDue, selectedTermId]);

    const onAccordionChange = () => {
        dispatch(deskingGlobalActionCreators.togglePaymentDetailsAccordion());
    };

    return (
        <Accordion
            contained={true}
            onChange={onAccordionChange}
            expanded={paymentDetailsAccordionExpanded}
            data-testid="desking-card-payment-details-accordion"
            sx={{ height: '100%' }}
        >
            <AccordionSummary chevronPosition="left" sx={{ padding: theme.tokens.ComponentCardPadding }}>
                <StyledPaymentTitle theme={theme} data-testid="desking-card-payment-details" className={'multi-card-view'}>
                    <Typography data-testid="desking-card-payment-details-title" variant={'h4'}>
                        Payment Details
                    </Typography>
                    <Typography data-testid="desking-card-payment-details-label" variant="h3">
                        {paymentDetailLabel}
                    </Typography>
                </StyledPaymentTitle>
            </AccordionSummary>
            <AccordionDetails>
                <InterstateDealSummary />
            </AccordionDetails>
        </Accordion>
    );
};
