import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ProgramsListView } from './ProgramsListView';
import {
    offerReduxSelectors,
    programsSelectors,
    programActionCreators,
    mapPaymentResponseToProgramQuote
} from '@makemydeal/dr-dash-store';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Alert } from '@interstate/components/Alert';
import { isProgramNotExpired } from './utils';
import { Typography } from '@interstate/components/Typography';
import { LoadingIndicatorWrapper } from './styles';

export const Programs = () => {
    const dispatch = useDispatch();

    const errorDesc = 'No Programs Found';
    const loadingDesc = 'Loading Programs...';

    const quotes = useSelector(programsSelectors.getActivePrograms);
    const isError = useSelector(programsSelectors.getProgramsLoadingError);
    const isLoading = useSelector(programsSelectors.getProgramsLoadingStatus);

    const programs = useMemo(() => quotes.map(mapPaymentResponseToProgramQuote), [quotes]);
    const programsToDisplay = programs.filter(isProgramNotExpired);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    useEffect(() => {
        dispatch(programActionCreators.getAllQuotes());
        return () => {
            dispatch(programActionCreators.clear());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Typography variant="h2" sx={{ margin: '2rem 0 1rem' }}>
                Active Programs
            </Typography>
            {isLoading && (
                <LoadingIndicatorWrapper>
                    <LoadingIndicator message={loadingDesc} size="large" />
                </LoadingIndicatorWrapper>
            )}
            {isError && (
                <Alert type="error" role="alert" title={errorDesc} sx={{ '.MuiAlertTitle-root': { margin: '0 !important' } }} />
            )}
            {!isLoading && <ProgramsListView data={programsToDisplay} offerType={offerType} />}
        </div>
    );
};
